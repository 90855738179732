import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useGetSinglePost } from "../../shared/queries";

import CircularProgress from "@material-ui/core/CircularProgress";

import DateCalendarServerRequest from "../../components/Calendar";
import { SliderBlogger } from "../../components/Slider";
import MenuItem from "@mui/material/MenuItem";

import Select from "@mui/material/Select";

const API_KEY = "AIzaSyC3Y6bo7hx1zBqItmHhdv8DzIcioLWgmK0";

const state = {
  list: [
    {
      text: "Преролл",
    },
    {
      text: "Мидролл",
    },
    {
      text: "Интеграция / 1",
    },
    {
      text: "Интеграция / 2",
    },
  ],
};

export const SingleBlogPost = () => {
  const history = useHistory();
  const [subscribeCount, setSubscribeCount] = useState("0");

  const { postId } = useParams();

  const { data: post, isLoading } = useGetSinglePost(postId);
  const [personInformation, setIspersonInfo] = useState(undefined);

  const [theme, setTheme] = React.useState("Показать все:");
  const [calendarDate, setCalendarDate] = React.useState([]);

  const youtubeId = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/channel\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
  };

  const selectIsChange = (event) => {
    setTheme(event.target.value);

    const filterDate = post.calendar.filter(
      (item) => item.theme === event.target.value
    );
    setCalendarDate(filterDate);
  };

  useEffect(() => {
    !isLoading && setCalendarDate(post.calendar);
  }, [isLoading, post]);

  useEffect(() => {
    !isLoading &&
      history.replace(`/calendar/${postId}?influencer=${post.bloggers_url}`);
  }, [isLoading, post, postId, history]);

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  if (isLoading) {
    return (
      <div className="peaches-calendar">
        <div className="container">
          <div className="peaches-calendar__main bg-gray">
            <CircularProgress style={{ color: "black" }} />
          </div>
        </div>
      </div>
    );
  }
  if (postId !== undefined && post.youtube_url !== undefined) {
    fetch(
      `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${youtubeId(
        post.youtube_url
      )}&key=${API_KEY}`
    )
      .then((data) => data.json())
      .then((result) => {
        setSubscribeCount(
          numberWithSpaces(result.items[0].statistics.subscriberCount)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      {!isLoading && (
        <section className="peaches-calendar">
          <div className="container">
            <div className="peaches-calendar__main">
              <div className="peaches-calendar__row">
                <div className="peaches-calendar__box">
                  <SliderBlogger post={post} postId={postId} />
                  <div className="peaches-calendar__overlay">
                    <img
                      src={`https://platform.peaches.studio/assets/images/${post.bloggers_url}.webp`}
                      alt="faces"
                      className="bloggers"
                    />
                    <a
                      className="peaches-calendar__information"
                      href={`https://platform.peaches.studio/${post.bloggers_url}`}
                    >
                      <h1 className="peaches-calendar__title">
                        <span data-label={post.title}>{post.title}</span>
                      </h1>
                      <div className="studio-stats__button">
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="peaches-calendar__person">
                  <div className="peaches-calendar__info">
                    <div className="peaches-calendar__info-wrap">
                      <div className="peaches-calendar__info-more">
                        <div className="peaches-calendar__choose">
                          {post.theme.length !== 0 && (
                            <>
                              <div className="peaches-calendar__theme">
                                Тема ролика
                              </div>
                              <Select
                                value={theme}
                                className="peaches-calendar__select"
                                renderValue={(value) => {
                                  return <>{value}</>;
                                }}
                                onChange={(event) => selectIsChange(event)}
                              >
                                {post.theme.map((item, index) => {
                                  return (
                                    <MenuItem value={item.theme} key={index}>
                                      <div className="peaches-calendar__select-text">
                                        {item.theme}
                                      </div>
                                      <div className="peaches-calendar__select-month">
                                        {item.month}
                                      </div>
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </>
                          )}
                          {post.calendar.length === 0 ? (
                            <>
                              <h2>
                                <span>
                                  Блогер выпускает видео по заказу
                                  рекламодателей. Обычно он публикует 1-2 ролика
                                  в месяц.
                                </span>
                              </h2>
                              <div className="studio-stats__button">
                                <div className="btn-outline">
                                  <a
                                    href="https://t.me/kalishevskaya"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span data-label="Оставить заявку">
                                      Оставить заявку
                                    </span>
                                  </a>
                                </div>
                                <img
                                  className="lazy-load entered loading"
                                  src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                                  alt="arrow"
                                />
                              </div>
                            </>
                          ) : (
                            personInformation === undefined && (
                              <div className="peaches-calendar__choose">
                                <h1>
                                  Пожалуйста, выберите дату со свободными
                                  слотами.
                                </h1>
                                <h2>
                                  <span>
                                    Такие даты подсвечиваются цветовыми
                                    индикаторами
                                  </span>
                                  <div className="peaches-calendar__choose-wrap">
                                    {arrowRight}
                                    <div className="peaches-calendar__choose-variant">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </h2>
                                <div className="studio-stats__button">
                                  <div className="btn-outline">
                                    <a
                                      href="https://t.me/kalishevskaya/?comment=<123123>&t=<123123>"
                                      // href="https://t.me/kalishevskaya/?text=123123"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span data-label="Оставить заявку">
                                        Оставить заявку
                                      </span>
                                    </a>
                                  </div>
                                  <img
                                    className="lazy-load entered loading"
                                    src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                                    alt="arrow"
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>

                        <div className="peaches-calendar__info-date">
                          {personInformation && personInformation.date}
                        </div>
                        <div className="peaches-calendar__info-name">
                          {personInformation && personInformation.title}
                        </div>
                      </div>

                      {personInformation !== undefined && (
                        <div className="studio-stats__button">
                          <div className="btn-outline">
                            <a
                              href="https://t.me/kalishevskaya"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span data-label="Забронировать слот">
                                Забронировать слот
                              </span>
                            </a>
                          </div>
                          <img
                            className="lazy-load entered loading"
                            src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                            alt="arrow"
                          />
                        </div>
                      )}
                    </div>
                    {personInformation !== undefined && (
                      <div className="peaches-calendar__slots">
                        <span className="peaches-calendar__slots-head">
                          Свободные слоты:
                        </span>
                        <div className="peaches-calendar__slots-row">
                          {personInformation.preroal_ev === true && (
                            <div className="peaches-calendar__slots-item green-tag">
                              <div className="peaches-calendar__slots-line"></div>
                              <span>Преролл</span>
                            </div>
                          )}

                          {personInformation.midrol_ev === true && (
                            <div className="peaches-calendar__slots-item orange-tag">
                              <div className="peaches-calendar__slots-line"></div>
                              <span>Мидролл</span>
                            </div>
                          )}
                          {personInformation.integration_ev === true && (
                            <div className="peaches-calendar__slots-item blue-tag">
                              <div className="peaches-calendar__slots-line"></div>
                              <span>Интеграция / 1</span>
                            </div>
                          )}
                          {personInformation.integration_ev_2 === true && (
                            <div className="peaches-calendar__slots-item violet-tag">
                              <div className="peaches-calendar__slots-line"></div>
                              <span>Интеграция / 2</span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {subscribeCount && (
                    <a
                      href={`https://www.youtube.com/channel/${post.youtube_url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="peaches-calendar__youtube"
                    >
                      <div className="peaches-calendar__youtube-block">
                        {youtubeIcon}
                        <p>YouTube</p>
                      </div>
                      <span className={isLoading ? "" : "count"}>
                        {subscribeCount}
                      </span>
                    </a>
                  )}
                </div>
                <div className="peaches-calendar__item">
                  {post.calendar.length === 0 && (
                    <div className="peaches-calendar__overlays">
                      {lockIcon}
                      <p>Пожалуйста, оставьте индивидуальную заявку</p>
                    </div>
                  )}
                  <DateCalendarServerRequest
                    personInfo={setIspersonInfo}
                    dates={calendarDate.filter(
                      (item) =>
                        item.date >= dayjs(new Date()).format("DD.MM.YYYY")
                    )}
                  />
                  <ul className="peaches-calendar__list">
                    {state.list.map((item, index) => {
                      return <li key={index}>{item.text}</li>;
                    })}
                  </ul>
                </div>
              </div>
              <div className="peaches-calendar__boxes">
                <div className="peaches-calendar__text">{post.description}</div>
                <div className="peaches-calendar__social">
                  <div className="peaches-calendar__social-info">
                    <span>Остались вопросы?</span>
                    <p>Свяжитесь с нами</p>
                  </div>
                  <ul className="peaches-calendar__social-list">
                    <li>
                      <a
                        href="https://t.me/kalishevskaya"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {telegramIcon}
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:hello@peaches.studio"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {mainIcon}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const youtubeIcon = (
  <svg
    width="164"
    height="115"
    viewBox="0 0 164 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M159.865 17.8494C157.984 10.8199 152.459 5.29569 145.429 3.41397C132.701 1.02193e-06 81.6393 0 81.6393 0C81.6393 0 30.5779 1.02193e-06 17.8494 3.41397C10.8199 5.29569 5.29569 10.8199 3.41397 17.8494C1.02193e-06 30.5779 0 57.1504 0 57.1504C0 57.1504 1.02193e-06 83.723 3.41397 96.4516C5.29569 103.481 10.8199 109.005 17.8494 110.887C30.5779 114.301 81.6393 114.301 81.6393 114.301C81.6393 114.301 132.701 114.301 145.429 110.887C152.459 109.005 157.984 103.481 159.865 96.4516C163.279 83.723 163.279 57.1504 163.279 57.1504C163.279 57.1504 163.265 30.5779 159.865 17.8494Z"
      fill="#FF0000"
    />
    <path
      d="M65.2969 81.6414L107.716 57.1524L65.2969 32.6631V81.6414Z"
      fill="white"
    />
  </svg>
);

const telegramIcon = (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.0003 2.15509C23.8607 2.81663 23.7167 3.47684 23.5822 4.13969C22.5557 9.18534 21.5317 14.231 20.5059 19.2767C20.4295 19.6528 20.36 20.0316 20.2668 20.4038C19.9207 21.788 18.9599 21.7045 18.2145 21.1429C16.6798 19.9868 15.1645 18.8032 13.641 17.6307C13.4145 17.4565 13.181 17.2907 12.9632 17.1046C12.8393 16.9988 12.7642 17.0119 12.6521 17.1257C11.7702 18.0194 10.8808 18.9058 9.99583 19.7968C9.73233 20.0618 9.43315 20.2249 9.03257 20.2091C9.05573 19.8474 9.07701 19.4983 9.1008 19.1491C9.20469 17.6195 9.30546 16.09 9.41938 14.5618C9.42814 14.4408 9.49824 14.2961 9.58461 14.2146C12.8962 11.0805 16.2129 7.95171 19.5289 4.82292C19.5695 4.78478 19.624 4.75387 19.6478 4.70587C19.701 4.59802 19.7392 4.48163 19.783 4.36919C19.6684 4.33762 19.5445 4.26003 19.4425 4.28501C19.2572 4.33039 19.072 4.4139 18.9098 4.52043C14.7814 7.22837 10.6555 9.94025 6.53212 12.6574C6.37815 12.7593 6.25046 12.7712 6.08085 12.7153C4.3565 12.1451 2.6284 11.5875 0.904682 11.0141C0.678734 10.9391 0.454037 10.8234 0.262512 10.6774C-0.0523135 10.4374 -0.0923709 10.0915 0.188031 9.81531C0.42149 9.58581 0.702518 9.37736 0.998567 9.25439C2.71603 8.54091 4.44287 7.85439 6.16785 7.16064C11.5531 4.99389 16.9376 2.8278 22.3241 0.665662C22.4969 0.596616 22.6827 0.542694 22.8655 0.528884C23.4319 0.485484 23.8019 0.777452 23.9496 1.35415C23.9633 1.40873 23.9834 1.462 24.0003 1.51592V2.15575V2.15509Z"></path>
  </svg>
);

const mainIcon = (
  <svg
    width="27"
    height="21"
    viewBox="0 0 27 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.0007 16.9072C26.9322 17.1678 26.8785 17.433 26.7922 17.6874C26.2251 19.3598 24.7666 20.4154 22.9378 20.4185C16.6448 20.4294 10.3526 20.431 4.05961 20.4185C2.06358 20.4146 0.511724 19.1529 0.094006 17.2526C0.0341097 16.9803 0.00610626 16.6956 0.00532838 16.4171C-0.000894605 13.0583 0.000661142 9.70097 0.000661142 6.34367C0.000661142 6.29233 0.00532838 6.24177 0.00999563 6.15309C0.119676 6.2161 0.208353 6.2651 0.294697 6.31722C4.36376 8.80175 8.43592 11.2816 12.4995 13.7763C13.174 14.1901 13.7939 14.1909 14.4683 13.7778C18.5576 11.2746 22.6547 8.78541 26.7494 6.29233C26.8319 6.24254 26.9166 6.19743 26.9999 6.14998L27.0007 16.9072Z"></path>
    <path d="M13.5009 0.525254C16.6202 0.525254 19.7403 0.525254 22.8595 0.525254C24.3297 0.525254 25.5012 1.10866 26.3141 2.34081C26.5233 2.65818 26.6392 3.03623 26.8018 3.38472C26.8944 3.58385 26.8236 3.68887 26.6392 3.80088C23.4219 5.75879 20.2085 7.7237 16.9951 9.68783C15.9015 10.356 14.8054 11.0211 13.7156 11.6963C13.5663 11.7889 13.4652 11.7858 13.3166 11.6947C8.99393 9.05386 4.66895 6.41765 0.340087 3.78532C0.176734 3.68576 0.134728 3.59708 0.188402 3.40805C0.678462 1.67028 2.23265 0.528366 4.14233 0.526032C7.26161 0.522921 10.3817 0.525254 13.5009 0.525254Z"></path>
  </svg>
);

const arrowRight = (
  <svg
    width="31"
    height="9"
    viewBox="0 0 31 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.3536 4.85355C30.5488 4.65829 30.5488 4.34171 30.3536 4.14645L27.1716 0.964466C26.9763 0.769204 26.6597 0.769204 26.4645 0.964466C26.2692 1.15973 26.2692 1.47631 26.4645 1.67157L29.2929 4.5L26.4645 7.32843C26.2692 7.52369 26.2692 7.84027 26.4645 8.03553C26.6597 8.2308 26.9763 8.2308 27.1716 8.03553L30.3536 4.85355ZM0.5 5H30V4H0.5V5Z"
      fill="white"
    />
  </svg>
);

const lockIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="50px"
    height="50px"
  >
    <path d="M 25 3 C 18.363281 3 13 8.363281 13 15 L 13 20 L 9 20 C 7.355469 20 6 21.355469 6 23 L 6 47 C 6 48.644531 7.355469 50 9 50 L 41 50 C 42.644531 50 44 48.644531 44 47 L 44 23 C 44 21.355469 42.644531 20 41 20 L 37 20 L 37 15 C 37 8.363281 31.636719 3 25 3 Z M 25 5 C 30.566406 5 35 9.433594 35 15 L 35 20 L 15 20 L 15 15 C 15 9.433594 19.433594 5 25 5 Z M 9 22 L 41 22 C 41.554688 22 42 22.445313 42 23 L 42 47 C 42 47.554688 41.554688 48 41 48 L 9 48 C 8.445313 48 8 47.554688 8 47 L 8 23 C 8 22.445313 8.445313 22 9 22 Z M 25 30 C 23.300781 30 22 31.300781 22 33 C 22 33.898438 22.398438 34.6875 23 35.1875 L 23 38 C 23 39.101563 23.898438 40 25 40 C 26.101563 40 27 39.101563 27 38 L 27 35.1875 C 27.601563 34.6875 28 33.898438 28 33 C 28 31.300781 26.699219 30 25 30 Z" />
  </svg>
);

import React from "react";
import { FormControl, Input } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";

const DeletePostForm = (props) => {
  const formRef = useRef(null);
  const [postName, setpostName] = useState(props.selectedPost.title);
  const [postDesc, setPostDesc] = useState(props.selectedPost.description);
  const [youtubeUrl, setYoutubeUrl] = useState(props.selectedPost.youtube_url);

  const [calendarTheme, setcalendarTheme] = useState([
    ...props.selectedPost.theme,
  ]);
  const [calendarThemeTitle, setcalendarMainThemeTitle] = useState("");
  const [calendarThemeMonth, setcalendarMainThemeMonth] = useState("");

  const [calendarMain, setcalendarMain] = useState([
    ...props.selectedPost.calendar,
  ]);

  const [bloggersUrl, setIsBloggersUrl] = useState(
    props.selectedPost.bloggers_url
  );

  const handlepostNameChange = (e) => {
    setpostName(e.target.value);
  };

  const handlePostDescChange = (e) => {
    setPostDesc(e.target.value);
  };
  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
  };

  const handleBloggersUrlChange = (e) => {
    setIsBloggersUrl(e.target.value);
  };

  const handlepostCalendarThemeTitleChange = (e) => {
    setcalendarMainThemeTitle(e.target.value);
  };
  const handlepostCalendarThemeMonthChange = (e) => {
    setcalendarMainThemeMonth(e.target.value);
  };

  const savePost = (e) => {
    e.preventDefault();
    const post = {
      id: props.selectedPost.id,
      title: postName,
      description: postDesc,
      youtube_url: youtubeUrl,
      bloggers_url: bloggersUrl,
      calendar: [...calendarMain],
      theme: [...calendarTheme],
    };
    props.editBlogPost(post);
    props.handleDeleteFormHide();
  };

  const addTheme = () => {
    setcalendarTheme([
      ...calendarTheme,
      { theme: calendarThemeTitle, month: calendarThemeMonth },
    ]);
    setcalendarMainThemeTitle("");
    setcalendarMainThemeMonth("");
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        props.handleDeleteFormHide();
      }
    };
    window.addEventListener("keyup", handleEscape);

    return () => window.removeEventListener("keyup", handleEscape);
  }, [props]);

  // React.useEffect(() => {

  //   const d1 = dayjs(new Date()).$d;

  //   typeof d1 === "object" &&
  //     d1 !== null &&
  //     "toLocaleDateString" in d1 &&
  //     console.log(d1.toLocaleDateString("ru-Ru"));

  //   const newList = calendarMain.filter(
  //     (item) => item.date <= d1.toLocaleDateString("ru-Ru")
  //   );
  //   setcalendarMain(newList);

  // }, []);
  // React.useEffect(() => {

  //   const d1 = dayjs(new Date()).$d;

  //   typeof d1 === "object" &&
  //     d1 !== null &&
  //     "toLocaleDateString" in d1 &&
  //     console.log(d1.toLocaleDateString("ru-Ru"));

  //   const newList = calendarMain.filter(
  //     (item) => item.date <= d1.toLocaleDateString("ru-Ru")
  //   );
  //   setcalendarMain(newList);

  // }, []);

  function handleRemove(date) {
    const newList = calendarMain.filter((item) => item.date !== date);

    setcalendarMain(newList);
  }

  function handleRemoveTheme(theme) {
    const newList = calendarTheme.filter((item) => item.theme !== theme);
    setcalendarTheme(newList);
  }
  function handleRemoveEvent(item) {
    // const filterObject = calendarMain.filter((box) => box.date === item.date);
    item.integration_ev = false;
    console.log(item);
  }

  // function handleRemoveItem(date) {
  //   const newList = calendarMain.filter((item) => item.date === date);
  // }

  const handleEditFormHide = props.handleDeleteFormHide;
  return (
    <>
      <div className="peaches-form">
        <div
          className="peaches-form__overlay"
          onClick={handleEditFormHide}
        ></div>

        <form
          onSubmit={savePost}
          ref={formRef}
          className="peaches-form__main full-form "
        >
          <div className="peaches-form__close" onClick={handleEditFormHide}>
            <img
              src="https://platform.peaches.studio/assets/images/icon/delete.svg"
              alt="delete"
            />
          </div>
          <h1 className="peaches-form__title">
            Удаление события ({props.selectedPost.title})
          </h1>

          <div className="peaches-form__grid">
            <div className="peaches-form__column">
              <FormControl>
                <div className="peaches-form__subhead">Заголовок поста</div>
                <Input
                  className="editFormInput"
                  type="text"
                  name="postName"
                  placeholder="Имя блогера"
                  value={postName}
                  onChange={handlepostNameChange}
                  required
                />
              </FormControl>
              <FormControl>
                <div className="peaches-form__subhead">Ссылка на блогера</div>
                <Input
                  className="editFormInput"
                  type="text"
                  name="postName"
                  placeholder="Ссылка на блогера"
                  value={bloggersUrl}
                  onChange={handleBloggersUrlChange}
                  required
                />
              </FormControl>
              <FormControl>
                <div className="peaches-form__subhead">
                  Ссылка на канал ютуба
                </div>
                <Input
                  className="editFormInput"
                  type="text"
                  name="postName"
                  placeholder="Ссылка на ютуб"
                  value={youtubeUrl}
                  onChange={handleYoutubeUrlChange}
                  required
                />
              </FormControl>
              <FormControl>
                <div className="peaches-form__subhead">
                  Описание инфлюенсера
                </div>
                <TextArea
                  className="editFormInput"
                  name="postDescription"
                  placeholder="Описание инфлюенсера"
                  value={postDesc}
                  onChange={handlePostDescChange}
                  rows={5}
                  required
                />
              </FormControl>
            </div>
            <div className="peaches-form__wrap">
              <div className="peaches-form__column-row">
                <FormControl>
                  <div className="peaches-form__subhead">Введите тему:</div>
                  <Input
                    className="editFormInput"
                    type="text"
                    name="postName"
                    placeholder="Тема"
                    value={calendarThemeTitle}
                    onChange={handlepostCalendarThemeTitleChange}
                  />
                </FormControl>
                <FormControl>
                  <div className="peaches-form__subhead">Введите месяц:</div>
                  <Input
                    className="editFormInput"
                    type="text"
                    name="postName"
                    placeholder="Месяц"
                    value={calendarThemeMonth}
                    onChange={handlepostCalendarThemeMonthChange}
                  />
                </FormControl>

                <button
                  className="peaches-form__button"
                  type="button"
                  onClick={() => addTheme()}
                >
                  Добавить тему
                </button>
              </div>

              {calendarTheme.length === 0 ? null : (
                <ul className="peaches-form__list">
                  {calendarTheme.map((item, index) => {
                    return (
                      <li key={index}>
                        <span>{item.theme}</span>
                        <span>{item.month}</span>
                        <img
                          src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                          alt=""
                          onClick={() => handleRemoveTheme(item.theme)}
                          className="peaches-form__card-close"
                        />
                      </li>
                    );
                  })}
                </ul>
              )}
              {calendarMain.length === 0 ? null : (
                <div className="peaches-form__inwrapper">
                  <FormControl>
                    <div className="peaches-form__subhead">Все события</div>
                    <div className="peaches-form__boxes">
                      <div className="peaches-form__row">
                        {calendarMain
                          .filter(
                            (item) =>
                              item.date >=
                              dayjs(new Date()).format("DD.MM.YYYY")
                          )
                          .map((item, index) => {
                            return (
                              <div key={index} className="peaches-form__card">
                                <img
                                  src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                                  alt=""
                                  onClick={() => handleRemove(item.date)}
                                  className="peaches-form__card-close"
                                />
                                Дата: {item.date}
                                <br></br>
                                Название: {item.title}
                                <br></br>
                                Тема: {item.theme}
                                <br></br>
                                {item.preroal_ev && (
                                  <span
                                    style={{
                                      borderColor: "var(--color-green-100)",
                                    }}
                                  >
                                    Преролл
                                    {/* <img
                                    src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                                    alt=""
                                    className="peaches-form__card-close"
                                  /> */}
                                  </span>
                                )}
                                {item.integration_ev && (
                                  <span
                                    style={{
                                      borderColor: "var(--color-blue-100)",
                                    }}
                                  >
                                    Интеграция / 1
                                    {/* <img
                                    onClick={() => handleRemoveEvent(item)}
                                    src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                                    alt=""
                                    className="peaches-form__card-close"
                                  /> */}
                                  </span>
                                )}
                                {item.integration_ev_2 && (
                                  <span
                                    style={{
                                      borderColor: "var(--color-violet-100)",
                                    }}
                                  >
                                    Интеграция / 2
                                    {/* <img
                                    src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                                    alt=""
                                    className="peaches-form__card-close"
                                  /> */}
                                  </span>
                                )}
                                {item.midrol_ev && (
                                  <span
                                    style={{
                                      borderColor: "var(--color-orange-100)",
                                    }}
                                  >
                                    {" "}
                                    Мидрол
                                    {/* <img
                                    src="https://platform.peaches.studio/assets/images/icon/delete.svg"
                                    alt=""
                                    className="peaches-form__card-close"
                                  /> */}
                                  </span>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
          <button className="peaches-form__button" type="submit">
            Обновить
          </button>
        </form>
      </div>
    </>
  );
};

export { DeletePostForm };

import React, { useState } from "react";
import { PersonBlogger } from "./PersonBlogger";
import { AddPostForm } from "./AddPostForm";
import { EditPostForm } from "../../components/EditPostForm/EditPostForm";
import { DeletePostForm } from "../../components/EditPostForm/DeletePostForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useAddPost,
  useDeletePost,
  useEditPost,
  useGetPosts,
} from "../../shared/queries";

export const Blog = ({ isAdmin }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectedPost, setSelectedPost] = useState({});

  const { data: posts, isLoading, isError, error, isFetching } = useGetPosts();

  const deleteMutation = useDeletePost();
  const editMutation = useEditPost();
  const addMutation = useAddPost();

  if (isLoading)
    return (
      <div className="peaches-calendar">
        <div className="container">
          <div className="peaches-calendar__main bg-gray">
            <CircularProgress style={{ color: "black" }} />
          </div>
        </div>
      </div>
    );

  if (isError)
    return (
      <div className="peaches-calendar">
        <div className="container">
          <div className="peaches-calendar__main bg-gray">{error.message}</div>
        </div>
      </div>
    );
  if (isError) return <h1>{error.message}</h1>;

  const deletePost = (blogPost) => {
    if (window.confirm(`Удалить ${blogPost.title}?`)) {
      deleteMutation.mutate(blogPost);
    }
  };

  const editBlogPost = (updatedBlogPost) => {
    editMutation.mutate(updatedBlogPost);
  };

  const addNewBlogPost = (newBlogPost) => {
    addMutation.mutate(newBlogPost);
  };

  const handleAddFormShow = () => {
    setShowAddForm(true);
  };

  const handleAddFormHide = () => {
    setShowAddForm(false);
    document.body.classList.remove("is-hidden");
  };

  const handleEditFormShow = () => {
    setShowEditForm(true);
  };
  const handleDeleteFormShow = () => {
    setShowDeleteForm(true);
  };

  const handleEditFormHide = () => {
    setShowEditForm(false);
  };
  const handleDeleteFormHide = () => {
    setShowDeleteForm(false);
  };

  const handleSelectPost = (blogPost) => {
    setSelectedPost(blogPost);
  };

  const blogPosts = posts.map((item) => {
    return (
      <div className="studio-faces__box " key={item.id} data-scroll>
        <PersonBlogger
          title={item.title}
          id={item.id}
          url={item.bloggers_url}
          description={item.description}
          youtubeUrl={item.youtube_url}
          deletePost={() => deletePost(item)}
          handleEditFormShow={handleEditFormShow}
          handleDeleteFormShow={handleDeleteFormShow}
          handleSelectPost={() => handleSelectPost(item)}
          isAdmin={isAdmin}
        />
      </div>
    );
  });

  const postsOpactiy = isFetching ? 0 : 1;

  return (
    <div className="studio-faces">
      <div className="container">
        <div className="studio-faces__content">
          {showAddForm && (
            <AddPostForm
              addNewBlogPost={addNewBlogPost}
              handleAddFormHide={handleAddFormHide}
            />
          )}

          {showEditForm && (
            <EditPostForm
              handleEditFormHide={handleEditFormHide}
              selectedPost={selectedPost}
              editBlogPost={editBlogPost}
            />
          )}
          {showDeleteForm && (
            <DeletePostForm
              handleDeleteFormHide={handleDeleteFormHide}
              selectedPost={selectedPost}
              editBlogPost={editBlogPost}
            />
          )}
        </div>
        <>
          <div className="studio-faces__wrap">
            <h3 className="studio-faces__head">Выберите блогера</h3>
            {isAdmin && (
              <div className="studio-stats__button dark">
                <div className="btn-outline">
                  <button onClick={handleAddFormShow}>
                    <span data-label="Создать новый пост">
                      Создать новый пост
                    </span>
                  </button>
                </div>
                <img
                  className="lazy-load entered loading"
                  src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                  alt="arrow"
                  data-ll-status="loading"
                />
              </div>
            )}
          </div>

          <div
            className="studio-faces__row"
            data-scroll
            style={{ opacity: postsOpactiy }}
          >
            {blogPosts}
          </div>
        </>
      </div>
    </div>
  );
};

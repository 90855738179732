import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { PublicRoute } from "./components/PublicRoute/PublicRoute";
import { Blog } from "./pages/Blog/Blog";
import { SingleBlogPost } from "./pages/SingleBlogPost/SingleBlogPost";
import { InfluencerPerson } from "./pages/Influencer";
import { Login } from "./pages/Login";
import { useLocation } from "react-router-dom";
import { NoMatch } from "./pages/NoMatch/NoMatch";
import { Home } from "./pages/Home";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const AppRoutes = ({
  isLoggedIn,
  setIsLoggedIn,
  setUserName,
  setIsAdmin,
  isAdmin,
}) => {
  let query = useQuery();
  const location = useLocation();

  return (
    <SwitchTransition>
      <CSSTransition
        key={location.pathname}
        timeout={300}
        classNames="page"
        unmountOnExit
      >
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (isLoggedIn) return <Home />;
              return <Redirect to="/login" />;
            }}
          ></Route>
          <PublicRoute isLoggedIn={isLoggedIn} path="/login" exact>
            <Login
              setIsLoggedIn={setIsLoggedIn}
              setUserName={setUserName}
              setIsAdmin={setIsAdmin}
            />
          </PublicRoute>

          <PrivateRoute isLoggedIn={isLoggedIn} path="/calendar/:postId" exact>
            <SingleBlogPost isAdmin={isAdmin} title={query.get("title")} />
          </PrivateRoute>

          <PrivateRoute isLoggedIn={isLoggedIn} path="/influencer/:id" exact>
            <InfluencerPerson isAdmin={isAdmin} title={query.get("title")} />
          </PrivateRoute>

          <PrivateRoute isLoggedIn={isLoggedIn} path="/calendar" exact>
            <Blog isAdmin={isAdmin} />
          </PrivateRoute>

          <Route exact path="/404">
            <NoMatch />
          </Route>

          <Route
            path="*"
            render={({ location }) => {
              return (
                <Redirect
                  to={{
                    pathname: "/404",
                    from: location,
                  }}
                />
              );
            }}
          />
        </Switch>
      </CSSTransition>
    </SwitchTransition>
  );
};

import { Link } from "react-router-dom";
/* eslint-disable jsx-a11y/img-redundant-alt */
export const PersonBlogger = ({
  title,
  url,
  id,
  deletePost,
  handleEditFormShow,
  handleDeleteFormShow,
  handleSelectPost,
  isAdmin,
}) => {
  const showEditForm = () => {
    handleSelectPost();
    handleEditFormShow();
  };
  const showDeleteForm = () => {
    handleSelectPost();
    handleDeleteFormShow();
  };

  return (
    <div className="studio-faces__item" data-scroll-section>
      <figure className="studio-faces__figure">
        <img
          src={`https://platform.peaches.studio/assets/images/${url}.webp`}
          alt="image"
          className={isAdmin && "filter-opacity"}
        />
      </figure>
      <Link to={`/calendar/${id}`} className="studio-faces__link"></Link>
      {isAdmin && (
        <>
          <div className="studio-faces__control">
            <>
              <div className="studio-faces__btn" onClick={showEditForm}>
                Добавить событие
              </div>
              <div className="studio-faces__btn" onClick={showDeleteForm}>
                Удалить событие
              </div>
            </>
          </div>
          <div className="studio-faces__delete" onClick={deletePost}>
            <img
              src="https://platform.peaches.studio/assets/images/icon/delete.svg"
              alt="delete"
            />
          </div>
        </>
      )}
      <div className="studio-faces__info">
        <div className="studio-faces__title">
          <span data-label={title}>{title}</span>
        </div>
      </div>
    </div>
  );
};

import { NavLink } from "react-router-dom";

const menu = [
  {
    href: "/",
    text: "Главная",
  },
  {
    href: "https://peaches.studio/cases/",
    text: "Кейсы",
  },
  {
    href: "https://peaches.studio/contact",
    text: "Контакты",
  },
];

const MenuStudio = ({ navbarOpen, handleLogOut }) => {
  return (
    <>
      <div className="nav-mobile">
        <div className={`nav-mobile__box ${navbarOpen ? "" : "hide"}`}>
          <span></span>
          <div className="nav-mobile__list">
            <ul>
              {menu.map((item, index) => {
                return (
                  <li key={index}>
                    <a href={item.href}>
                      <span data-label={item.text}>{item.text}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="studio-stats__button dark">
            <div className="btn-outline">
              <NavLink onClick={handleLogOut} exact to="/login">
                <span data-label="Выйти">Выйти</span>
              </NavLink>
            </div>
          </div>
          <div className="nav-mobile__social">
            <ul>
              <li>
                <a href="https://t.me/kalishevskaya">
                  <span data-label="Telegram">Telegram</span>
                </a>
              </li>
              <li>
                <a href="mailto:hello@peaches.studio">
                  <span data-label="Email">Email</span>
                </a>
              </li>
              <li>
                <div className="nav-mobile__text">© 2023</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { MenuStudio };

import * as React from "react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { FormControl, Input } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ruRU } from "@mui/x-date-pickers/locales";
import "dayjs/locale/ru";

export const EditPostForm = (props) => {
  const [dateCalendar, setDateCalendar] = React.useState(dayjs(new Date()));
  const [chooseDateCalendar, setChooseDateCalendar] = useState();

  const [postName, setpostName] = useState(props.selectedPost.title);
  const [postDesc, setPostDesc] = useState(props.selectedPost.description);
  const [youtubeUrl, setYoutubeUrl] = useState(props.selectedPost.youtube_url);

  const [bloggersUrl, setIsBloggersUrl] = useState(
    props.selectedPost.bloggers_url
  );

  const [calendarMainTitle, setcalendarMainTitle] = useState(
    props.selectedPost.calendar.title
  );
  const [calendarTheme, setcalendarTheme] = useState(
    props.selectedPost.calendar.theme
  );

  const [calendarMain, setcalendarMain] = useState([
    ...props.selectedPost.calendar,
  ]);

  const [checkedEventPreroal, checkedSetEventPreroal] = React.useState(false);
  const [checkedEventAll, checkedSetEventAll] = React.useState(false);

  const [checkedEventIntegration, checkedSetEventIntegration] =
    React.useState(false);
  const [checkedEventIntegrationSlot2, checkedSetEventIntegrationSlot2] =
    React.useState(false);

  const [checkedEventProduct, checkedSetEventProduct] = React.useState(false);

  const handleChangeEventPreroal = (event) => {
    checkedSetEventPreroal(event.target.checked);
  };
  const handleChangeAll = (event) => {
    checkedSetEventPreroal(!checkedEventPreroal);
    checkedSetEventIntegrationSlot2(!checkedEventIntegrationSlot2);
    checkedSetEventIntegration(!checkedEventIntegration);
    checkedSetEventProduct(!checkedEventProduct);
    checkedSetEventAll(event.target.checked);
  };
  const handleChangeEventProduct = (event) => {
    checkedSetEventProduct(event.target.checked);
  };

  const handleChangeEventIntegration = (event) => {
    checkedSetEventIntegration(event.target.checked);
  };
  const handleChangeEventIntegrationSlot2 = (event) => {
    checkedSetEventIntegrationSlot2(event.target.checked);
  };

  const handlepostCalendarTitleChange = (e) => {
    setcalendarMainTitle(e.target.value);
  };
  const themeOnChange = (e) => {
    setcalendarTheme(e.target.value);
  };

  const savePost = (e) => {
    e.preventDefault();
    const post = {
      id: props.selectedPost.id,
      title: postName,
      description: postDesc,
      youtube_url: youtubeUrl,
      bloggers_url: bloggersUrl,
      calendar: [
        ...calendarMain,
        {
          date: chooseDateCalendar,
          theme: calendarTheme,
          title: calendarMainTitle,
          preroal_ev: checkedEventPreroal,
          integration_ev: checkedEventIntegration,
          integration_ev_2: checkedEventIntegrationSlot2,
          midrol_ev: checkedEventProduct,
        },
      ],
    };
    props.editBlogPost(post);
    props.handleEditFormHide();
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        props.handleEditFormHide();
      }
    };
    window.addEventListener("keyup", handleEscape);

    return () => window.removeEventListener("keyup", handleEscape);
  }, [props]);

  useEffect(() => {
    const d1 = dateCalendar.$d;
    typeof d1 === "object" &&
      d1 !== null &&
      "toLocaleDateString" in d1 &&
      setChooseDateCalendar(d1.toLocaleDateString("ru-Ru"));
  }, [dateCalendar]);

  const handleEditFormHide = props.handleEditFormHide;
  return (
    <>
      <div className="peaches-form">
        <div
          className="peaches-form__overlay"
          onClick={handleEditFormHide}
        ></div>
        {/* <DeletePostForm props={props} /> */}
        <form className="peaches-form__main full-form " onSubmit={savePost}>
          <div className="peaches-form__close" onClick={handleEditFormHide}>
            <img
              src="https://platform.peaches.studio/assets/images/icon/delete.svg"
              alt="delete"
            />
          </div>
          <h1 className="peaches-form__title">
            Добавление события ({props.selectedPost.title})
          </h1>

          <div className="peaches-form__grid indent">
            <FormControl>
              <div className="peaches-form__subhead">Выберите дату</div>

              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="ru"
                localeText={
                  ruRU.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <DateCalendar
                  value={dateCalendar}
                  showDaysOutsideCurrentMonth
                  fixedWeekNumber={6}
                  onChange={(newValue) => {
                    setDateCalendar(newValue);
                  }}
                />
              </LocalizationProvider>
            </FormControl>

            <div className="peaches-form__column">
              <div className="peaches-form__columns">
                <FormControl>
                  <div className="peaches-form__subhead">Выберите тему: </div>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {props.selectedPost.theme.map((item, index) => {
                      return (
                        <FormControlLabel
                          value={item.theme}
                          onChange={themeOnChange}
                          control={<Radio />}
                          label={item.theme}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                <FormControl>
                  <div className="peaches-form__subhead">Название ролика </div>
                  <Input
                    type="text"
                    name="postName"
                    placeholder="Название ролика "
                    value={calendarMainTitle}
                    onChange={handlepostCalendarTitleChange}
                  />
                </FormControl>
              </div>
              <div className="peaches-form__events">
                <FormControl className="peaches-form__inbox">
                  <div className="peaches-form__subhead">Выбрать все</div>
                  <Checkbox
                    size="small"
                    sx={{
                      color: "var(--color-red)",
                      "&.Mui-checked": {
                        color: "var(--color-red)",
                      },
                    }}
                    className="peaches-form__checkbox"
                    checked={checkedEventAll}
                    onChange={handleChangeAll}
                  />
                </FormControl>
                <FormControl className="peaches-form__inboxes">
                  <div className="peaches-form__subhead">Преролл</div>
                  <Checkbox
                    size="small"
                    sx={{
                      color: "var(--color-green-100)",
                      "&.Mui-checked": {
                        color: "var(--color-green-100)",
                      },
                    }}
                    className="peaches-form__checkbox"
                    checked={checkedEventPreroal}
                    onChange={handleChangeEventPreroal}
                  />
                </FormControl>
                <FormControl className="peaches-form__inboxes">
                  <div className="peaches-form__subhead">Мидрол</div>
                  <Checkbox
                    size="small"
                    sx={{
                      color: "var(--color-orange-100)",
                      "&.Mui-checked": {
                        color: "var(--color-orange-100)",
                      },
                    }}
                    className="peaches-form__checkbox"
                    checked={checkedEventProduct}
                    onChange={handleChangeEventProduct}
                  />
                </FormControl>
                <FormControl className="peaches-form__inboxes">
                  <div className="peaches-form__subhead">Интеграция / 1</div>
                  <Checkbox
                    size="small"
                    sx={{
                      color: "var(--color-blue-100)",
                      "&.Mui-checked": {
                        color: "var(--color-blue-100)",
                      },
                    }}
                    className="peaches-form__checkbox"
                    checked={checkedEventIntegration}
                    onChange={handleChangeEventIntegration}
                  />
                </FormControl>
                <FormControl className="peaches-form__inboxes">
                  <div className="peaches-form__subhead">Интеграция / 2</div>
                  <Checkbox
                    size="small"
                    sx={{
                      color: "var(--color-violet-100)",
                      "&.Mui-checked": {
                        color: "var(--color-violet-100)",
                      },
                    }}
                    className="peaches-form__checkbox"
                    checked={checkedEventIntegrationSlot2}
                    onChange={handleChangeEventIntegrationSlot2}
                  />
                </FormControl>
              </div>

              {calendarMainTitle && (
                <div className="peaches-form__boxes">
                  <div className="peaches-form__subhead">
                    Блогер: {props.selectedPost.title}
                    <br></br>
                    Дата:{" "}
                    {dateCalendar &&
                      dateCalendar.format("DD.MM.YYYY").toString()}
                    <br></br>
                    Название ролика: {calendarMainTitle}
                    <br></br>
                    Тема ролика: {calendarTheme}
                    <br></br>
                    Вы выбрали вид:{" "}
                    {checkedEventPreroal && (
                      <span style={{ borderColor: "var(--color-green-100)" }}>
                        {" "}
                        Преролл
                      </span>
                    )}
                    {checkedEventProduct && (
                      <span style={{ borderColor: "var(--color-orange-100)" }}>
                        {" "}
                        Мидрол
                      </span>
                    )}
                    {checkedEventIntegration && (
                      <span style={{ borderColor: "var(--color-blue-100)" }}>
                        Интеграция / 1
                      </span>
                    )}
                    {checkedEventIntegrationSlot2 && (
                      <span style={{ borderColor: "var(--color-violet-100)" }}>
                        Интеграция / 2
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <button className="peaches-form__button" type="submit">
            Добавить событие
          </button>
        </form>
      </div>
    </>
  );
};

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormControl, Input } from "@mui/material";
import { userPerson } from "./userPerson";

export const Login = ({ setIsLoggedIn, setUserName, setIsAdmin }) => {
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState("");
  const [isDisableButton, setDisableButton] = useState(false);

  const handleLoginChange = (e) => {
    setLogin(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const successLoginAdmin = () => {
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("userName", login);

    setUserName(login);
    setIsLoggedIn(true);
    history.push("/");
    setIsAdmin(true);
  };

  const errorForm = () => {
    setDisableButton(true);
    setIsError("Введите правильный логин или пароль!");
  };

  const sucessLogin = () => {
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("userName", login);
    setUserName(login);
    setIsLoggedIn(true);
    history.push("/");
  };

  useEffect(() => {
    login === "" || password === ""
      ? setDisableButton(true)
      : setDisableButton(false);
    login === "" || (password === "" && setIsError(""));
  }, [login, password, isError]);

  const handleLogIn = (e) => {
    e.preventDefault();
    login === userPerson.admin.login && password === userPerson.admin.password
      ? successLoginAdmin()
      : errorForm();

    login === userPerson.user.login && password === userPerson.user.password
      ? sucessLogin()
      : errorForm();
  };

  return (
    <section className="peaches-login">
      <div className="container">
        <form className="peaches-login__form" onSubmit={handleLogIn}>
          <h1 className="peaches-login__title">Вход в профиль</h1>
          <FormControl>
            <Input
              className="peaches-login__input"
              type="text"
              placeholder="Логин"
              onChange={handleLoginChange}
              value={login}
              required
            />
          </FormControl>
          <FormControl>
            <div
              className="peaches-login__icon"
              onClick={() => togglePassword()}
            >
              {passwordType === "password" ? (
                <>{eyeIcon}</>
              ) : (
                <> {eyeCloseIcon}</>
              )}
            </div>
            <Input
              className="peaches-login__input"
              type={passwordType}
              placeholder="Пароль"
              onChange={handlePasswordChange}
              value={password}
              required
            />
          </FormControl>

          {isError && <div className="peaches-login__error">{isError}</div>}
          <button
            className="peaches-login__button"
            type="submit"
            disabled={isDisableButton}
          >
            <span data-label="Отправить">Войти</span>
          </button>
          <div className="peaches-login__subtext">
            Нет профиля?
            <a
              href="https://t.me/kalishevskaya"
              target="_blank"
              rel="noreferrer"
            >
              Напиши нам
            </a>
          </div>
          <a className="peaches-login__logo" href="/">
            <img
              src="https://platform.peaches.studio/assets/images/logo.svg"
              alt="logo"
            />
          </a>
        </form>
      </div>
    </section>
  );
};

const eyeCloseIcon = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2L22 22"
      stroke="#000000"
      stroke-width="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335"
      stroke="#000000"
      stroke-width="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const eyeIcon = (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="12"
      r="3"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

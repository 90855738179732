/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const blogger = [
  {
    href: "/influencer/liza",
    poster: "https://platform.peaches.studio/assets/images/liza.webp",
    video: "https://platform.peaches.studio/7c897070da9f8acb5cda.mp4",
    title: "Лиза Анохина",
  },
  {
    href: "/influencer/graf",
    img: "https://platform.peaches.studio/assets/images/graf.webp",
    title: " Дарья Граф",
  },
  {
    href: "/influencer/doshik",
    img: "https://platform.peaches.studio/assets/images/doshik.webp",
    title: "Даша Дошик",
  },
  {
    href: "/influencer/rakhim",
    img: "https://platform.peaches.studio/assets/images/rakhim.webp",
    title: "Rakhim",
  },
  {
    href: "/influencer/vlad-a4",
    img: "https://platform.peaches.studio/assets/images/vlad-a4.webp",
    title: "Влад А4",
  },
  {
    href: "/influencer/amina",
    img: "https://platform.peaches.studio/assets/images/amina.webp",
    title: "Амина Тендерлибае ",
  },
  {
    href: "/influencer/nysha",
    poster: "https://platform.peaches.studio/assets/images/nysha.webp",
    video: "https://platform.peaches.studio/3117912fd62a57fb049f.mp4",
    title: "Нюша",
  },
  {
    href: "/influencer/instrinna",
    img: "https://platform.peaches.studio/assets/images/instrinna.webp",
    title: "Inst_Rinna",
  },
  {
    href: "/influencer/nikita",
    img: "https://platform.peaches.studio/assets/images/nikita.webp",
    title: "Никита Ефремов",
  },
  {
    href: "/influencer/egorik",
    img: "https://platform.peaches.studio/assets/images/egorik.webp",
    title: "Егорик",
  },
  {
    href: "/influencer/vlad",
    img: "https://platform.peaches.studio/assets/images/vlad.webp",
    title: "Влад Хошин",
  },
  {
    href: "/influencer/anastasile",
    poster: "https://platform.peaches.studio/assets/images/anastasile.webp",
    video: "https://platform.peaches.studio/7f4afe615412e8616de4.mov",
    title: "Anastasile",
  },
  {
    href: "/influencer/jony",
    img: "https://platform.peaches.studio/assets/images/jony.webp",
    title: "Jony",
  },
  {
    href: "/influencer/limba",
    img: "https://platform.peaches.studio/assets/images/limba.webp",
    title: "The Limba",
  },
  {
    href: "/influencer/emil",
    img: "https://platform.peaches.studio/assets/images/emil.webp",
    title: "Эмиль Иманов",
  },
  {
    href: "/influencer/enikeeva",
    img: "https://platform.peaches.studio/assets/images/enikeeva.webp",
    title: "Алия Еникеева ",
  },
  {
    href: "/influencer/avemi",
    img: "https://platform.peaches.studio/assets/images/avemi.webp",
    title: "Лисса Авеми",
  },
  {
    href: "/influencer/alehey",
    img: "https://platform.peaches.studio/assets/images/alehey.webp",
    title: "Алексей Столяров",
  },
  {
    href: "/influencer/danik",
    img: "https://platform.peaches.studio/assets/images/danik.webp",
    title: "Даник",
  },
  {
    href: "/influencer/gabar",
    img: "https://platform.peaches.studio/assets/images/gabar.webp",
    title: "Габар",
  },
  {
    href: "/influencer/yyana",
    img: "https://platform.peaches.studio/assets/images/yyana.webp",
    title: "Яяна",
  },
  {
    href: "/influencer/stas",
    img: "https://platform.peaches.studio/assets/images/stas.webp",
    title: "Супер Стас",
  },
  {
    href: "/influencer/akyuliych",
    img: "https://platform.peaches.studio/assets/images/akyuliych.webp",
    title: "Akyuliych",
  },
  {
    href: "/influencer/bad-barbie",
    img: "https://platform.peaches.studio/assets/images/bad-barbie.webp",
    title: "Bad Barbie ",
  },
  {
    href: "/influencer/hahadetka",
    img: "https://platform.peaches.studio/assets/images/bloggers-2.webp",
    title: "Hahadetka",
  },
  {
    href: "/influencer/alisha",
    img: "https://platform.peaches.studio/assets/images/alisha.webp",
    title: "Alisha",
  },
  {
    href: "/influencer/chanderland",
    img: "https://platform.peaches.studio/assets/images/chanderland.webp",
    title: "Chanderland",
  },
  {
    href: "/influencer/pauline-cake",
    img: "https://platform.peaches.studio/assets/images/pauline-cake.webp",
    title: "Pauline Cake",
  },
  {
    href: "/influencer/maria",
    poster: "https://platform.peaches.studio/assets/images/maria.webp",
    video: "https://platform.peaches.studio/f3742f5e343b4dc2db7e.mp4",
    title: "Мария Гоголь",
  },
  {
    href: "/influencer/eva",
    img: "https://platform.peaches.studio/assets/images/eva.webp",
    title: "Eva Mosevich",
  },
  {
    href: "/influencer/sevinch",
    img: "https://platform.peaches.studio/assets/images/sevinch.webp",
    title: "Севинч Салманова",
  },
  {
    href: "/influencer/feeviun",
    img: "https://platform.peaches.studio/assets/images/feeviun.webp",
    title: "Feeviun",
  },
  { info: true },
];

export const Home = () => {
  return (
    <>
      <section
        className="peaches-studio__page"
        data-scroll-section="data-scroll-section"
      >
        <div className="container">
          <div className="peaches-studio__content ">
            <div className="peaches-studio__main">
              <div className="peaches-studio__boxes " data-scroll>
                <h1>
                  Помогаем расти бизнесу <br />
                  по всей России и СНГ, <br />
                  <span>
                    об этом говорят <br />
                    наши цифры
                  </span>
                </h1>
                <p>
                  Запускаем рекламные кампании, специально разработанные для
                  эффективного решения ваших бизнес-задач, обеспечивая точное
                  попадание в цели и стратегические приоритеты вашего бизнеса
                </p>
              </div>
              <div className="peaches-studio__rows ">
                <div className="peaches-studio__items">
                  <span>Охват аудитории более</span>
                  <p>400 млн.</p>
                </div>
                <div className="peaches-studio__items i-box">
                  {" "}
                  <span>
                    Партнерских
                    <br />
                    блогеров
                  </span>
                  <p>350+</p>
                </div>
                <div className="peaches-studio__column">
                  <div className="peaches-studio__items">
                    <span>
                      Эксклюзивных
                      <br />
                      блогеров
                    </span>
                    <p>15+</p>
                  </div>
                  <div className="peaches-studio__items">
                    <span>
                      Реализованных <br />
                      рекламных кампаний
                    </span>
                    <p>400+</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="peaches-studio__info">
            <div className="peaches-studio__info-item ">
              <p>Индивидуальный подход</p>
              <div className="peaches-studio__info-box">
                <div className="peaches-studio__info-text">
                  Опираясь на многолетний опыт и экспертизу, мы предлагаем
                  высокий уровень сервиса, разрабатывая индивидуальные решения
                  для каждого клиента. Мы создаем выгодные условия для всех
                  участников сделки, что подтверждается долгосрочным
                  сотрудничеством с ведущими брендами.
                </div>
              </div>
            </div>
            <div className="peaches-studio__info-item ">
              <p>Стратегия</p>
              <div className="peaches-studio__info-box">
                <div className="peaches-studio__info-text">
                  Для каждого клиента мы разрабатываем комплексную стратегию, от
                  подбора инфлюенсеров до креативных концепций, контролируя весь
                  процесс реализации. Эта стратегия позволяет нам достигать
                  высоких результатов и превращать маркетинговые усилия в успех.
                </div>
              </div>
            </div>
            <div className="peaches-studio__info-item ">
              <p>Рекламные кампании</p>
              <div className="peaches-studio__info-box">
                <div className="peaches-studio__info-text">
                  Объединяем эксклюзивных и партнерских блогеров для эффективных
                  рекламных кампаний. Наш инновационный подход обеспечивает
                  максимальную охватимость и уникальные рекламные сообщения.
                </div>
              </div>
            </div>
            <div className="peaches-studio__info-item ">
              <p>Цели</p>
              <div className="peaches-studio__info-box">
                <div className="peaches-studio__info-text">
                  Работаем с вами, чтобы решить ключевые бизнес-задачи и
                  превзойти KPI. Наша миссия — не просто увеличить узнаваемость,
                  но и укрепить лояльность и продажи, обеспечивая долгосрочный
                  успех вашего бизнеса.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="peaches-studio"
        data-scroll-section="data-scroll-section"
      >
        <div className="container">
          <div className="peaches-studio__content">
            <div className="peaches-studio__box">
              <h1 className="peaches-studio__title">
                Герои, которые задают тренды
              </h1>
              <div className="peaches-studio__inwrap">
                <a
                  className="peaches-studio__download"
                  href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit#gid=1039655954"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                    alt="download"
                  />
                  <span data-label="Price List">Price List</span>
                </a>
                <a
                  className="peaches-studio__download"
                  href="https://platform.peaches.studio/presentation/presentation-2023.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://platform.peaches.studio/assets/images/icon/download.svg"
                    alt="download"
                  />
                  <span data-label="Скачать презентацию">
                    Скачать презентацию
                  </span>
                </a>
              </div>
            </div>

            <div className="studio-faces__row peaches-faces">
              {blogger.map((item) => {
                return item.info ? (
                  <div className="studio-stats__information">
                    <div className="studio-stats__mores">
                      <div className="studio-stats__name">
                        <div className="studio-stats__information-row">
                          <div className="studio-stats__button dark">
                            <div className="btn-outline">
                              <a
                                href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <span data-label="Посмотреть всех">
                                  Посмотреть всех
                                </span>
                              </a>
                            </div>
                            <img
                              src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                              alt="main"
                            />
                            <a
                              className="mobile"
                              href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit?usp=sharing"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                                alt="main"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Link to={item.href} className="studio-faces__item">
                    <figure className="studio-faces__figure">
                      {item.video ? (
                        <video
                          autoPlay="autoplay"
                          poster={item.poster}
                          muted={true}
                          playsInline=""
                          preload="auto"
                          nocontrols=""
                          controlslList="nodownload"
                          loop="loop"
                        >
                          <source src={item.video} type="video/mp4"></source>|
                          Sorry, your browser doesn't support embedded videos.
                        </video>
                      ) : (
                        <img src={item.img} alt="faces image"></img>
                      )}
                    </figure>
                    <div className="studio-faces__info">
                      <div className="studio-faces__title">
                        <span data-label={item.title}>{item.title}</span>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./AppRoutes";
import { Header } from "./components/common/Header";
import ScrollToTop from "./scrollTop";
import { Footer } from "./components/common/Footer";
import { MenuStudio } from "./components/common/Header/menu-studio";
// import Lenis from "@studio-freight/lenis";

export function App() {
  // const lenis = new Lenis({
  //   lerp: 0,
  // });

  // function raf(time) {
  //   lenis.raf(time);
  //   requestAnimationFrame(raf);
  // }

  // requestAnimationFrame(raf);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  const [navbarOpen, setNavbarOpen] = useState(false);

  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("userName") === "admin"
  );

  const handleLogOut = () => {
    setNavbarOpen(false);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userName");
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  const openMenu = () => {
    setNavbarOpen(!navbarOpen);
  };

  return (
    <Router>
      <ScrollToTop />
      <div className={`${navbarOpen ? "nav-active" : ""}`}>
        <Header
          navbarOpen={navbarOpen}
          userName={userName}
          openMenu={openMenu}
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          setIsAdmin={setIsAdmin}
          handleLogOut={handleLogOut}
          isAdmin={isAdmin}
        />
        <main>
          <MenuStudio
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setIsAdmin={setIsAdmin}
            isAdmin={isAdmin}
            handleLogOut={handleLogOut}
            navbarOpen={navbarOpen}
          />
          <AppRoutes
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setUserName={setUserName}
            setIsAdmin={setIsAdmin}
            isAdmin={isAdmin}
          />
        </main>
        <Footer isLoggedIn={isLoggedIn} />
      </div>
    </Router>
  );
}

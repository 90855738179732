import { useState } from "react";
import { NavLink } from "react-router-dom";
import { DarkModeToggle } from "@anatoliygatt/dark-mode-toggle";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import useScrollListener from "../../hook/useScrollListener";

export const Header = ({ navbarOpen, openMenu, isLoggedIn, handleLogOut }) => {
  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScrollListener();

  const html = document.querySelector("html");

  useEffect(() => {
    const _classList = [];

    scroll.y > 150 && scroll.y - scroll.lastY > 0
      ? _classList.push("hide nav-header")
      : _classList.push(" nav-header");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  const [mode, setMode] = useState(() => {
    const initialTheme = localStorage.getItem("theme");
    return initialTheme ? initialTheme : "dark";
  });

  function getThemeFromLocalStorage() {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setMode(savedTheme);
    }
  }

  useEffect(() => {
    getThemeFromLocalStorage();
  }, [mode]);

  useEffect(() => {
    html.className = mode;
  }, [mode]);

  return (
    <>
      {isLoggedIn && (
        <>

            <header className={navClassList.join(" ")}>
              <div className="container">
                <div className="nav-header__main">
                  <Link to={`/`} className="nav-header__logo">
                    <img
                      src="https://platform.peaches.studio/assets/images/logo.svg"
                      alt="logo"
                    />
                  </Link>

                  <nav className="nav-header__list">
                    <ul>
                      <li>
                        <Link to={`/calendar/`} className="studio-faces__link">
                          <span data-label="Календарь"> Календарь</span>
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://peaches.studio/cases/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span data-label="Кейсы">Кейсы</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://peaches.studio/contact"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span data-label="Контакты">Контакты</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <div className="nav-header__wrap">
                    <DarkModeToggle
                      mode={mode}
                      size="sm"
                      inactiveTrackColor="#e2e8f0"
                      inactiveTrackColorOnHover="#f8fafc"
                      inactiveTrackColorOnActive="#cbd5e1"
                      activeTrackColor="#334155"
                      activeTrackColorOnHover="#1e293b"
                      activeTrackColorOnActive="#0f172a"
                      inactiveThumbColor="#1e293b"
                      activeThumbColor="#e2e8f0"
                      onChange={(mode) => {
                        localStorage.setItem("theme", mode);
                        setMode(mode);
                      }}
                    />

                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <NavLink onClick={handleLogOut} exact to="/login">
                          <span data-label="Выйти">Выйти</span>
                        </NavLink>
                      </div>
                    </div>
                    <div
                      className={`nav-header__hamburger ${
                        navbarOpen ? "active" : ""
                      }`}
                      onClick={() => openMenu()}
                    >
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </header>
        </>
      )}
    </>
  );
};

import React, { useState } from "react";
import { FormControl, Input } from "@mui/material";
import TextArea from "antd/lib/input/TextArea";

export const AddPostForm = ({ addNewBlogPost, handleAddFormHide }) => {
  const [postName, setpostName] = useState("");
  const [postDesc, setPostDesc] = useState("");
  const [youtubeUrl, setYoutubeUrl] = useState("");

  const [bloggersUrl, setIsBloggersUrl] = useState("");

  const handlepostNameChange = (e) => {
    setpostName(e.target.value);
  };

  const handleBloggersUrlChange = (e) => {
    setIsBloggersUrl(e.target.value);
  };
  const handlePostDescChange = (e) => {
    setPostDesc(e.target.value);
  };
  const handleYoutubeUrlChange = (e) => {
    setYoutubeUrl(e.target.value);
  };

  const createPost = (e) => {
    e.preventDefault();
    const post = {
      title: postName,
      description: postDesc,
      youtube_url: youtubeUrl,

      bloggers_url: bloggersUrl,
      calendar: [],
    };

    addNewBlogPost(post);
    handleAddFormHide();
  };

  useState(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        handleAddFormHide();
        document.body.classList.add("is-hidden");
      }
    };

    window.addEventListener("keyup", handleEscape);
    document.body.classList.add("is-hidden");
    return () => {
      window.removeEventListener("keyup", handleEscape);
    };
  }, []);

  return (
    <>
      <div className="peaches-form">
        <div
          className="peaches-form__overlay"
          onClick={handleAddFormHide}
        ></div>

        <form className="peaches-form__main full-form " onSubmit={createPost}>
          <div className="peaches-form__close" onClick={handleAddFormHide}>
            <img
              src="https://platform.peaches.studio/assets/images/icon/delete.svg"
              alt="delete"
            />
          </div>
          <h1 className="peaches-form__title">Добавить блогера</h1>

          <div className="peaches-form__columns">
            <FormControl>
              <div className="peaches-form__subhead">Заголовок поста</div>
              <Input
                className="editFormInput"
                type="text"
                name="postName"
                placeholder="Имя блогера"
                value={postName}
                onChange={handlepostNameChange}
                required
              />
            </FormControl>
            <FormControl>
              <div className="peaches-form__subhead">Ссылка на блогера</div>
              <Input
                className="editFormInput"
                type="text"
                name="postName"
                placeholder="Ссылка на блогера"
                value={bloggersUrl}
                onChange={handleBloggersUrlChange}
                required
              />
            </FormControl>
            <FormControl>
              <div className="peaches-form__subhead">Ссылка на канал ютуба</div>
              <Input
                className="editFormInput"
                type="text"
                name="postName"
                placeholder="Ссылка на ютуб"
                value={youtubeUrl}
                onChange={handleYoutubeUrlChange}
                required
              />
            </FormControl>
            <FormControl>
              <div className="peaches-form__subhead">Описание инфлюенсера</div>
              <TextArea
                className="editFormInput"
                name="postDescription"
                placeholder="Описание инфлюенсера"
                value={postDesc}
                onChange={handlePostDescChange}
                rows={5}
                required
              />
            </FormControl>
          </div>

          <button className="peaches-form__button" type="submit">
            Обновить пост
          </button>
        </form>
      </div>
    </>
  );
};

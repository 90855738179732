import React, { useCallback, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";

import { useGetPosts } from "../../shared/queries";

import "swiper/swiper.min.css";

const SliderBlogger = ({ postId }) => {
  const { data: posts, isLoading } = useGetPosts();

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="slider-bloggers__section">
          {/* <CircularProgress style={{ color: "white" }} /> */}
        </div>
      ) : (
        <div className="slider-bloggers__section">
          {/* <div className="slider-bloggers__section"> */}
          <div
            className={
              isLoading
                ? "slider-bloggers__inboxes"
                : "slider-bloggers__inboxes active"
            }
          >
            <div className="slider-bloggers__wrap">
              <h3 className="slider-bloggers__title">Выберите блогера</h3>
              <div className="slider-bloggers__nav">
                <div className="slider-bloggers__nav-prev" onClick={handlePrev}>
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ArrowLeftIcon"
                  >
                    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                  </svg>
                </div>
                <div className="slider-bloggers__nav-next" onClick={handleNext}>
                  <svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="ArrowLeftIcon"
                  >
                    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <Swiper
              modules={[FreeMode]}
              slidesPerView={"auto"}
              speed={500}
              freeMode={true}
              ref={sliderRef}
              className="slider-bloggers"
            >
              {posts.map((item) => {
                return (
                  <div key={item.id}>
                    {postId === item.id ? null : (
                      <SwiperSlide key={item.id}>
                        <a
                          href={`/calendar/${item.id}?influencer=${item.bloggers_url}`}
                        >
                          <img
                            src={`https://platform.peaches.studio/assets/images/${item.bloggers_url}.webp`}
                            alt="blogger img"
                          />
                        </a>
                        <span> {item.title}</span>
                      </SwiperSlide>
                    )}
                  </div>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export { SliderBlogger };

const userPerson = {
  admin: {
    login: "admin",
    password: "j0NrfCFsJE3w",
  },
  user: {
    login: "client",
    password: "142857",
  },
};

export { userPerson };

import React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";

export const NoMatch = () => {
  const location = useLocation();
  const history = useHistory();

  const backToHome = () => {
    history.push("/");
  };

  if (!location?.from?.pathname) return <Redirect to="/" />;

  return (
    <div className="peaches-error">
      <div className="container">
        <div className="peaches-error__content">
          <div className="peaches-error__main">
            <h1 className="peaches-error__title">
              Страница <span>{`${location.from.pathname}`} </span> <br></br>не
              найдена
            </h1>
            <div className="peaches-error__text">
              Ссылка, по которой вы перешли, отсутсвует. <br></br>Возможно, в
              ней была опечатка, или эта страница была удалена.
            </div>

            <div className="studio-stats__button dark peaches-error__button">
              <div className="btn-outline">
                <button onClick={backToHome}>
                  <span data-label="Напишите нам"> На главную</span>
                </button>
              </div>
              <img
                className="lazy-load entered loading"
                alt="arrow"
                src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                data-ll-status="loading"
              />
            </div>
          </div>
          <div className="peaches-error__img">{illustrationIcon}</div>
        </div>
      </div>
    </div>
  );
};

const illustrationIcon = (
  <svg
    width="882"
    height="543"
    viewBox="0 0 882 543"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M185.017 478V404.01H25.7671V373.63L191.877 132.55H225.687V369.71H277.137V404.01H225.687V478H185.017ZM185.017 369.71V198.7L67.4171 369.71H185.017ZM441.096 482.41C401.569 482.41 371.516 467.22 350.936 436.84C330.356 406.46 320.066 362.523 320.066 305.03C320.066 247.21 330.356 203.273 350.936 173.22C371.516 143.167 401.569 128.14 441.096 128.14C480.949 128.14 511.002 143.167 531.256 173.22C551.836 202.947 562.126 246.72 562.126 304.54C562.126 362.36 551.672 406.46 530.766 436.84C510.186 467.22 480.296 482.41 441.096 482.41ZM441.096 447.62C468.536 447.62 488.789 436.023 501.856 412.83C515.249 389.637 521.946 353.54 521.946 304.54C521.946 255.54 515.412 219.77 502.346 197.23C489.279 174.363 468.862 162.93 441.096 162.93C413.656 162.93 393.239 174.363 379.846 197.23C366.779 220.097 360.246 255.867 360.246 304.54C360.246 353.54 366.779 389.637 379.846 412.83C393.239 436.023 413.656 447.62 441.096 447.62ZM772.634 478V404.01H613.384V373.63L779.494 132.55H813.304V369.71H864.754V404.01H813.304V478H772.634ZM772.634 369.71V198.7L655.034 369.71H772.634Z"
      fill="#E9E9E9"
    />
    <g clip-path="url(#clip0_576_34)">
      <path
        d="M514.07 306.17L558.84 433.78C558.84 433.78 510.59 429.99 477.23 459.98C447.46 486.74 464.1 561.24 525.53 534.87C586.96 508.51 639.01 493.73 637 455.73C635.31 423.76 566.9 292.11 566.9 292.11L514.07 306.17Z"
        fill="#F2552C"
      />
      <path
        d="M630.98 187.39C617.39 165.67 597.05 112.49 620.49 100.08C648.9 85.0404 679.39 128.65 679.39 128.65C679.39 128.65 630.87 40.9504 662.63 26.6404C694.4 12.3304 721.44 107.61 721.44 107.61C721.44 107.61 693.83 2.75044 726.43 2.26044C758.67 1.78044 764.7 107.7 764.7 107.7C764.7 107.7 759.35 8.44044 789.45 0.900437C825.6 -8.15956 827.66 52.2704 807.11 165.25C786.56 278.23 753.54 310.92 680.49 304.75C607.44 298.58 585.26 257.27 585.26 257.27L590.13 199.39C590.13 199.39 638.58 249.41 691.23 252.33C666.22 237.92 640.74 202.98 630.99 187.4L630.98 187.39Z"
        fill="#F2552C"
      />
      <path
        d="M280.78 205.88C290.09 229.74 300.18 285.78 274.85 293.62C244.14 303.12 222.29 254.6 222.29 254.6C222.29 254.6 253.65 349.8 219.78 357.94C185.91 366.09 177.06 267.45 177.06 267.45C177.06 267.45 184.68 375.62 152.57 370.03C120.8 364.5 134.58 259.32 134.58 259.32C134.58 259.32 121.37 357.84 90.4002 359.66C53.1902 361.84 62.4102 302.08 103.62 194.9C144.81 87.7096 183.33 61.7296 253.96 81.3696C324.59 101.02 359.27 165.35 359.27 165.35L335.28 214.53C335.28 214.53 284.85 143.54 233.66 130.87C255.56 149.68 274.1 188.75 280.78 205.87V205.88Z"
        fill="#F2552C"
      />
      <path
        d="M391.631 327.89L374.861 456.08C374.861 456.08 332.431 428.27 291.291 446.15C255.081 461.89 242.631 540.86 309.471 541.63C376.311 542.41 429.891 549.99 443.441 514.43C454.841 484.52 445.621 336.44 445.621 336.44L391.621 327.89H391.631Z"
        fill="#F2552C"
      />
      <path
        d="M451.362 155.791C464.076 132.316 459.002 104.954 440.028 94.6783C421.055 84.4022 395.367 95.1023 382.652 118.578C369.938 142.053 375.012 169.414 393.986 179.691C412.959 189.967 438.648 179.267 451.362 155.791Z"
        fill="white"
      />
      <path
        d="M536.544 156.363C549.258 132.888 544.184 105.527 525.21 95.2506C506.236 84.9744 480.548 95.6746 467.834 119.15C455.12 142.626 460.194 169.987 479.167 180.263C498.141 190.539 523.829 179.839 536.544 156.363Z"
        fill="white"
      />
      <path
        d="M447.657 138.515C451.466 131.483 449.947 123.288 444.265 120.211C438.583 117.133 430.89 120.339 427.081 127.371C423.273 134.403 424.792 142.598 430.474 145.676C436.155 148.753 443.849 145.547 447.657 138.515Z"
        fill="#F2552C"
      />
      <path
        d="M534.499 138.517C538.308 131.485 536.789 123.29 531.107 120.212C525.425 117.135 517.732 120.341 513.923 127.373C510.115 134.405 511.633 142.6 517.315 145.677C522.997 148.755 530.691 145.549 534.499 138.517Z"
        fill="#F2552C"
      />
      <path
        d="M547.729 139.16C547.779 138.88 547.839 138.61 547.879 138.33C550.239 123.35 546.719 109.06 538.229 99.1197C531.069 90.7497 520.959 86.1396 509.749 86.1396C491.019 86.1396 472.879 99.0697 463.139 117.44C461.529 110.51 458.489 104.24 454.109 99.1197C446.949 90.7497 436.839 86.1396 425.629 86.1396C400.709 86.1396 376.829 109.04 372.389 137.18C372.219 138.28 372.079 139.38 371.969 140.47C344.239 160.43 325.619 197.3 325.619 239.49C325.619 302.92 367.679 354.34 419.559 354.34C434.349 354.34 448.339 350.16 460.779 342.71C473.229 350.15 487.209 354.34 501.999 354.34C553.879 354.34 595.939 302.92 595.939 239.49C595.939 196.35 576.479 158.79 547.709 139.15L547.729 139.16ZM509.749 93.5296C518.759 93.5296 526.879 97.2196 532.609 103.93C539.279 111.73 542.239 122.92 540.879 134.91C540.789 135.66 540.689 136.42 540.579 137.18C536.689 161.88 516.079 181.98 494.639 181.98C485.629 181.98 477.509 178.29 471.779 171.58C464.689 163.29 461.789 151.17 463.809 138.33C463.919 137.61 464.049 136.9 464.189 136.19C464.329 135.5 464.479 134.81 464.639 134.13C470.069 111.39 489.529 93.5296 509.739 93.5296H509.749ZM425.629 93.5296C434.639 93.5296 442.759 97.2196 448.489 103.93C454.999 111.55 457.969 122.4 456.839 134.06C456.799 134.49 456.749 134.92 456.699 135.35C456.629 135.96 456.549 136.57 456.449 137.18C452.559 161.88 431.949 181.98 410.509 181.98C401.499 181.98 393.379 178.29 387.649 171.58C380.559 163.29 377.659 151.17 379.679 138.33C379.769 137.73 379.889 137.14 379.999 136.55C380.089 136.08 380.189 135.61 380.289 135.14C385.379 111.92 405.109 93.5296 425.619 93.5296H425.629Z"
        fill="#F2552C"
      />
      <path
        d="M435.659 47.2403C454.529 40.0603 458.299 27.7803 444.929 20.3103C431.559 12.8503 386.849 24.2303 393.539 43.5403C396.569 52.3003 416.799 54.4203 435.659 47.2403Z"
        fill="#F2552C"
      />
      <path
        d="M513.561 33.4403C533.641 35.0303 542.801 25.5603 534.531 13.1103C526.261 0.660329 481.361 -8.18967 478.091 12.0903C476.611 21.2903 493.471 31.8503 513.551 33.4403H513.561Z"
        fill="#F2552C"
      />
      <path
        d="M432.61 256.46C428.49 256.59 424.71 255.01 421.97 252.01C418.82 248.55 417.38 243.54 418.04 238.27C419.27 228.38 427.18 220.41 436.03 220.13C440.15 220 443.93 221.58 446.67 224.58C449.82 228.04 451.26 233.05 450.6 238.32C449.37 248.21 441.46 256.18 432.61 256.46ZM436.26 227.17C430.96 227.34 425.83 232.82 425.04 239.14C424.64 242.37 425.42 245.32 427.18 247.26C428.53 248.74 430.32 249.48 432.38 249.41C437.68 249.24 442.81 243.76 443.6 237.44C444 234.21 443.22 231.26 441.46 229.32C440.11 227.84 438.32 227.1 436.26 227.17Z"
        fill="white"
      />
      <path
        d="M392.42 271.961C391.23 272.001 390.05 271.431 389.35 270.361C388.29 268.731 388.75 266.541 390.38 265.481C429.96 239.711 415.26 209.421 414.62 208.141C413.74 206.411 414.43 204.291 416.16 203.401C417.89 202.521 420 203.201 420.89 204.921C421.09 205.301 425.7 214.461 424.63 227.301C422.86 248.531 407.12 262.991 394.22 271.391C393.66 271.751 393.04 271.941 392.41 271.961H392.42Z"
        fill="white"
      />
      <path
        d="M465.729 289.51C464.749 289.54 463.769 289.17 463.049 288.4C462.249 287.54 443.409 267.15 446.289 240.97C449.179 214.7 474.329 198.75 475.399 198.09C477.049 197.06 479.229 197.57 480.259 199.22C481.289 200.87 480.779 203.05 479.129 204.08C478.729 204.33 455.799 219.01 453.299 241.75C450.779 264.65 468.019 283.4 468.189 283.59C469.519 285.01 469.449 287.24 468.029 288.57C467.379 289.18 466.559 289.5 465.729 289.52V289.51Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_576_34">
        <rect
          width="753.18"
          height="542.83"
          fill="white"
          transform="translate(67)"
        />
      </clipPath>
    </defs>
  </svg>
);

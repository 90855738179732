import * as React from "react";
import dayjs from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";

import { ruRU } from "@mui/x-date-pickers/locales";
import "dayjs/locale/ru";

export default function DateCalendarServerRequest({
  personInfo = {},
  dates = [],
}) {
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedDate, handleDateChange] = React.useState(dayjs(new Date()));
  const [chooseDateCalendar, setChooseDateCalendar] = React.useState({});
  const [dateCalendar, setDateCalendar] = React.useState({});

  React.useEffect(() => {
    var item = dates.find((item) => item.date === chooseDateCalendar);

    setDateCalendar(item);
    personInfo(dateCalendar);
  }, [dateCalendar, chooseDateCalendar, personInfo, dates]);

  React.useEffect(() => {
    const d1 = selectedDate.$d;
    typeof d1 === "object" &&
      d1 !== null &&
      "toLocaleDateString" in d1 &&
      setChooseDateCalendar(d1.toLocaleDateString("ru-Ru"));
  }, [selectedDate]);

  const dateDays = dates.map((item) => item.date);

  function ServerDay(props) {
    const { day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth && dateDays.includes(day.format("DD.MM.YYYY"));

    const objects = dates.find((obj) => {
      return obj.date === props.day.format("DD.MM.YYYY");
    });

    return (
      <>
        <div
          // className={isSelected ? "peaches-calendar__events" : undefined}
          className={
            isSelected
              ? objects.preroal_ev === true &&
                objects.integration_ev === true &&
                objects.integration_ev === true &&
                objects.midrol_ev === true
                ? "peaches-calendar__events view-3"
                : (objects.preroal_ev && objects.integration_ev) ||
                  (objects.integration_ev && objects.midrol_ev) ||
                  (objects.midrol_ev && objects.preroal_ev) ||
                  (objects.integration_ev_2 && objects.integration_ev)
                ? "peaches-calendar__events view-2"
                : "peaches-calendar__events view-1"
              : undefined
          }
          key={props.day.toString()}
        >
          {props.day.format("DD.MM.YYYY") && isSelected && objects && (
            <>
              <div className="peaches-calendar__evpoint">
                {objects.preroal_ev === true && (
                  <span className="peaches-calendar__point peaches-calendar__point--green "></span>
                )}
                {objects.midrol_ev === true && (
                  <span className="peaches-calendar__point peaches-calendar__point--orange "></span>
                )}
                {objects.integration_ev === true && (
                  <span className="peaches-calendar__point peaches-calendar__point--blue "></span>
                )}
                {objects.integration_ev_2 === true && (
                  <span className="peaches-calendar__point peaches-calendar__point--violet "></span>
                )}
              </div>
            </>
          )}

          <PickersDay
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ru"
        localeText={
          ruRU.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <DateCalendar
          defaultValue={selectedDate}
          loading={isLoading}
          renderLoading={() => <DayCalendarSkeleton />}
          value={selectedDate}
          format="DD-MM-YYYY"
          showDaysOutsideCurrentMonth
          onChange={(newValue) => {
            handleDateChange(newValue);
          }}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              dates,
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
}

import React from "react";

const Footer = ({ isLoggedIn }) => {
  return (
    <>
      {isLoggedIn && (
        <footer className="footer-studio" id="footer" data-scroll-section>
          <div className="container">
            <div className="footer-studio__content">
              <div className="footer-studio__main">
                <div className="footer-studio__inner">
                  <h2>
                    У вас есть предложение <br />о сотрудничестве?
                  </h2>
                  <div className="studio-stats__button dark">
                    <div className="btn-outline">
                      <a
                        href="mailto:hello@peaches.studio"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span data-label="Напишите нам">Напишите нам</span>
                      </a>
                    </div>
                    <img
                      className="lazy-load entered loading"
                      alt="arrow"
                      src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                      data-ll-status="loading"
                    />
                    <a
                      className="mobile"
                      href="mailto:hello@peaches.studio "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-studio__wrap">
                  <div className="studio-contact__block ">
                    <div className="studio-contact__item">
                      <span className="studio-contact__subtitle">
                        Для коммерческих <br />
                        предложений
                      </span>
                      <div className="studio-contact__button">
                        <div className="btn-outline">
                          <a href="mailto:offer@peaches.studio">
                            <span data-label="offer@peaches.studio">
                              offer@peaches.studio
                            </span>
                          </a>
                        </div>
                        <img
                          src="https://platform.peaches.studio/assets/images/icon/mail.svg"
                          alt="mail"
                        />
                      </div>
                    </div>
                    <div className="studio-contact__item">
                      <span className="studio-contact__subtitle">
                        По общим <br />
                        вопросам
                      </span>
                      <div className="studio-contact__button">
                        <div className="btn-outline">
                          <a href="mailto:hello@peaches.studio">
                            <span data-label="hello@peaches.studio">
                              hello@peaches.studio
                            </span>
                          </a>
                        </div>
                        <img
                          src="https://platform.peaches.studio/assets/images/icon/mail.svg"
                          alt="mail"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="footer-studio__inwrap">
                    <div className="footer-studio__more">
                      <a className="footer-studio__logo" href="/">
                        <img
                          src="https://platform.peaches.studio/assets/images/logo.svg"
                          alt="logo"
                        />
                      </a>
                      <div className="footer-studio__desc">
                        Все права защищены
                        <br />
                        Peaches Platform Ⓒ 2023
                      </div>
                    </div>
                    <ul className="footer-studio__list">
                      <li>
                        <a
                          href="https://t.me/kalishevskaya"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            width="24"
                            height="22"
                            viewBox="0 0 24 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M24.0003 2.15509C23.8607 2.81663 23.7167 3.47684 23.5822 4.13969C22.5557 9.18534 21.5317 14.231 20.5059 19.2767C20.4295 19.6528 20.36 20.0316 20.2668 20.4038C19.9207 21.788 18.9599 21.7045 18.2145 21.1429C16.6798 19.9868 15.1645 18.8032 13.641 17.6307C13.4145 17.4565 13.181 17.2907 12.9632 17.1046C12.8393 16.9988 12.7642 17.0119 12.6521 17.1257C11.7702 18.0194 10.8808 18.9058 9.99583 19.7968C9.73233 20.0618 9.43315 20.2249 9.03257 20.2091C9.05573 19.8474 9.07701 19.4983 9.1008 19.1491C9.20469 17.6195 9.30546 16.09 9.41938 14.5618C9.42814 14.4408 9.49824 14.2961 9.58461 14.2146C12.8962 11.0805 16.2129 7.95171 19.5289 4.82292C19.5695 4.78478 19.624 4.75387 19.6478 4.70587C19.701 4.59802 19.7392 4.48163 19.783 4.36919C19.6684 4.33762 19.5445 4.26003 19.4425 4.28501C19.2572 4.33039 19.072 4.4139 18.9098 4.52043C14.7814 7.22837 10.6555 9.94025 6.53212 12.6574C6.37815 12.7593 6.25046 12.7712 6.08085 12.7153C4.3565 12.1451 2.6284 11.5875 0.904682 11.0141C0.678734 10.9391 0.454037 10.8234 0.262512 10.6774C-0.0523135 10.4374 -0.0923709 10.0915 0.188031 9.81531C0.42149 9.58581 0.702518 9.37736 0.998567 9.25439C2.71603 8.54091 4.44287 7.85439 6.16785 7.16064C11.5531 4.99389 16.9376 2.8278 22.3241 0.665662C22.4969 0.596616 22.6827 0.542694 22.8655 0.528884C23.4319 0.485484 23.8019 0.777452 23.9496 1.35415C23.9633 1.40873 23.9834 1.462 24.0003 1.51592V2.15575V2.15509Z"></path>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="mailto:hello@peaches.studio"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            width="27"
                            height="21"
                            viewBox="0 0 27 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M27.0007 16.9072C26.9322 17.1678 26.8785 17.433 26.7922 17.6874C26.2251 19.3598 24.7666 20.4154 22.9378 20.4185C16.6448 20.4294 10.3526 20.431 4.05961 20.4185C2.06358 20.4146 0.511724 19.1529 0.094006 17.2526C0.0341097 16.9803 0.00610626 16.6956 0.00532838 16.4171C-0.000894605 13.0583 0.000661142 9.70097 0.000661142 6.34367C0.000661142 6.29233 0.00532838 6.24177 0.00999563 6.15309C0.119676 6.2161 0.208353 6.2651 0.294697 6.31722C4.36376 8.80175 8.43592 11.2816 12.4995 13.7763C13.174 14.1901 13.7939 14.1909 14.4683 13.7778C18.5576 11.2746 22.6547 8.78541 26.7494 6.29233C26.8319 6.24254 26.9166 6.19743 26.9999 6.14998L27.0007 16.9072Z"></path>
                            <path d="M13.5009 0.525254C16.6202 0.525254 19.7403 0.525254 22.8595 0.525254C24.3297 0.525254 25.5012 1.10866 26.3141 2.34081C26.5233 2.65818 26.6392 3.03623 26.8018 3.38472C26.8944 3.58385 26.8236 3.68887 26.6392 3.80088C23.4219 5.75879 20.2085 7.7237 16.9951 9.68783C15.9015 10.356 14.8054 11.0211 13.7156 11.6963C13.5663 11.7889 13.4652 11.7858 13.3166 11.6947C8.99393 9.05386 4.66895 6.41765 0.340087 3.78532C0.176734 3.68576 0.134728 3.59708 0.188402 3.40805C0.678462 1.67028 2.23265 0.528366 4.14233 0.526032C7.26161 0.522921 10.3817 0.525254 13.5009 0.525254Z"></path>
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export { Footer };

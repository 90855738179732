export const POSTS_URL = "https://64ef9511219b3e2873c4aada.mockapi.io/posts/";

export const INFLUENSER_PERSON = [
  {
    id: "liza",
    bloggerName: "Лиза Анохина",
    poster: "liza.webp",
    video: "7c897070da9f8acb5cda.mp4",
    social: [
      {
        link: "https://www.tiktok.com/@anokhinalz",
        title: "TikTok",
        subscribe: "39.6 млн.",
      },
      {
        link: "https://www.instagram.com/anokhina_elizabeth_2007",
        title: "Instagram",
        subscribe: "3.7 млн.",
      },
      {
        link: "https://www.youtube.com/@anokhina_elizabeth",
        title: "Youtube",
        subscribe: "1.17 млн.",
      },
      {
        link: "https://vk.com/anokhinaa_elizabeth",
        title: "Вконтакте",
        subscribe: "11.7 тыс.",
      },
    ],
    subtext:
      "Лиза — популярный TikTok-блогер с более чем 43 миллионами подписчиков. Она не только участвует в трендах, но и задаёт их сама. Неповторимый стиль Лизы привлекает всё больше фолловеров — и даже международных тиктокеров, с которыми Лиза активно коллабится.",
    facts: {
      title: "Факты",
      items: [
        {
          text: " Искренняя и жизнерадостная",
        },
        {
          text: "Фокус на молодёжную аудиторию ",
        },
        {
          text: "ыла амбассадором Samsung",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Лайфстайл",
        },
        {
          text: "Юмор",
        },
        {
          text: "Тренды",
        },
        {
          text: "Танцы",
        },
        {
          text: "Коллаборации с известными тиктокерами",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Bella Poarch",
          img: "bloggers-17.webp",
        },
        {
          text: "Эльдар Джарахов ",
          img: "bloggers-18.webp",
        },
        {
          text: "Дина Саева",
          img: "bloggers-4.webp",
        },
        {
          text: "Даша Дошик",
          img: "bloggers-15.webp",
        },
        {
          text: "Benji Krol",
          img: "bloggers-10.webp",
        },
        {
          text: "Michelle Kennelly  ",
          img: "bloggers-20.webp",
        },

        {
          text: "Esmi (Эсмиральда Данилевская)",
          img: "bloggers-22.webp",
        },
        {
          text: "Кира Симурина",
          img: "bloggers-21.webp",
        },
        {
          text: "Maria Jeleniewska ",
          img: "bloggers-19.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "samsung.svg",
        },
        {
          img: "prada.svg",
        },
        {
          img: "paco.svg",
        },
        {
          img: "sberbank.svg",
        },
        {
          img: "mvideo.svg",
        },
        {
          img: "cheetos.webp",
        },
        {
          img: "okko.webp",
        },
        {
          img: "mts.svg",
        },
        {
          img: "gold.svg",
        },
        {
          img: "alfabank.svg",
        },
        {
          img: "beautybomb.webp",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "cover-3.webp",
          link: "https://www.youtube.com/watch?v=n7XJyL7wZd4&feature=youtu.be&ab_channel=%D0%9F%D0%A3%D0%A8%D0%9A%D0%90",
          title: "Интервью Лизы YouTube каналу «Пушка»",
          text: "1,9 млн просмотров",
        },
        {
          bg: "cover-4.webp",
          link: "https://www.youtube.com/watch?v=bZMb01-tnyA&feature=youtu.be&ab_channel=%D0%9D%D0%BE%D1%87%D0%BD%D0%BE%D0%B9%D0%BA%D0%BE%D0%BD%D1%82%D0%B0%D0%BA%D1%82",
          title: "Лиза Анохина в шоу «Ночной Контакт»",
          text: "89 тыс. просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/14ju6O71Tf2eM81Ds-aKiMsYe6n4_fbjz",
        link: "https://www.instagram.com/anokhina_elizabeth_2007",
        country: [
          {
            title: "Россия",
            percent: "38.4",
          },
          {
            title: "Украина",
            percent: "6.3",
          },
          {
            title: "Казахстан",
            percent: "4.8",
          },

          {
            title: "США",
            percent: "4.4",
          },
        ],
        slider: [
          {
            img: "stats-1.webp",
          },
          {
            img: "stats-2.webp",
          },
          {
            img: "stats-3.webp",
          },
          {
            img: "stats-4.webp",
          },
          {
            img: "stats-5.webp",
          },
          {
            img: "stats-6.webp",
          },
        ],
        gender: {
          woman: "57.2",
          man: "42.7",
        },
        tags: [
          {
            text: "3.7 млн подписчиков",
          },
          {
            text: "200+ тыс просмотров stories",
          },
        ],
      },
      tikTok: {
        title: "TikTok",
        link: "https://www.tiktok.com/@anokhinalz",
        statsLink:
          "https://drive.google.com/drive/folders/1tc8lYHVwP3y9KFdyAsy50ANG1gwrOi_Y",
        country: [
          {
            title: "США",
            percent: "27",
          },
          {
            title: "Мехико",
            percent: "24",
          },
          {
            title: "Россия",
            percent: "20",
          },
          {
            title: "Бразилия",
            percent: "20",
          },
          {
            title: "Аргентина",
            percent: "9",
          },
        ],
        slider: [
          {
            img: "stats-10.webp",
          },
          {
            img: "stats-11.webp",
          },
          {
            img: "stats-12.webp",
          },
          {
            img: "stats-13.webp",
          },
          {
            img: "stats-14.webp",
          },
          {
            img: "stats-15.webp",
          },
          {
            img: "stats-16.webp",
          },
          {
            img: "stats-17.webp",
          },
        ],
        gender: {
          woman: "75",
          man: "25",
        },
        tags: [
          {
            text: "1.3 миллиарда лайков",
          },
          {
            text: "средний охват - 13 млн",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "YouTube",
          socialLink: "https://www.youtube.com/@anokhina_elizabeth",
          link: "https://drive.google.com/drive/folders/1EM2JW2y4k7uTruYZHwoTknidfmw72oBV?usp=share_link",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.com/anokhinaa_elizabeth",
          link: "https://drive.google.com/drive/folders/1HIBuqMu9iZWriMKGaRwsDQQLsmZc3XMl",
        },
      ],
    },
  },
  {
    id: "graf",
    bloggerName: "Дарья Граф",
    bloggerImage: "graf.webp",
    social: [
      {
        link: "https://www.youtube.com/c/DariaGraph",
        title: "Youtube",
        subscribe: "2 млн.",
      },
      {
        link: "https://www.tiktok.com/@dgraph",
        title: "TikTok",
        subscribe: "3.2 млн.",
      },
      {
        link: "https://www.instagram.com/cosmos_dg",
        title: "Instagram",
        subscribe: "673 тыс.",
      },
      {
        link: "https://t.me/graphdgn",
        title: "Telegram",
        subscribe: "173 тыс.",
      },
      {
        link: "https://vk.com/dariagraph",
        title: "Вконтакте",
        subscribe: "21.5 тыс.",
      },
    ],
    subtext:
      "Даша одна из самых неординарных бьюти-блогеров. В своих видео она органично смешивает смешные истории из жизни и обзоры на косметику. Даша известна своей любовью к большим цветным ногтям, сумасшедшим кислотным образам. Даша не прячется за фильтрами. Её харизма и честность – магнит для фоловеров и брендов.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Хорошее чувство юмора",
        },
        {
          text: "Обожает косплей и косметику",
        },
        {
          text: "Использует смелые образы",
        },
        {
          text: "Сотрудничает с крупными брендами",
        },
        {
          text: "Снимается в популярных шоу",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Макияжи и преображения",
        },
        {
          text: "Обзоры и распаковки",
        },
        {
          text: "Юмористические скетчи",
        },
        {
          text: "Танцы и челленджи",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Лисса Авеми",
          img: "bloggers-25.webp",
        },
        {
          text: "Луна Ком (Эвелина Тарханова)",
          img: "bloggers-23.webp",
        },
        {
          text: "Mark Kalinin",
          img: "bloggers-24.webp",
        },
        {
          text: "Хахадетка ",
          img: "bloggers-2.webp",
        },
        {
          text: "Катя Клэп",
          img: "bloggers-55.webp",
        },
        {
          text: "Anastasiya Shpagina",
          img: "bloggers-1.webp",
        },
        {
          text: "Инстасамка",
          img: "bloggers-31.webp",
        },
        {
          text: "Лиза Анохина",
          img: "bloggers-9.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "vk-2.svg",
        },
        {
          img: "alfabank.svg",
        },
        {
          img: "beautybomb.webp",
        },
        {
          img: "tinkoff.svg",
        },
        {
          img: "ivi.svg",
        },
        {
          img: "joom.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "cover-graf.webp",
          link: "https://www.youtube.com/watch?v=AoJukWY2Mrw&ab_channel=YesNoYes",
          title:
            "ДАША ГРАФ — скандал с Лунаком, переезд на Бали, ссора со Шпагиной | ДаДа — НетНет",
          text: "688 тыс. просмотров",
        },
        {
          bg: "cover-12.webp",
          link: "https://www.youtube.com/watch?v=2cWb193BTWg",
          title: "Интервью Даши на YouTube шоу «Пушка»",
          text: "3 млн просмотров",
        },
      ],
    },
    statistic: {
      youtube: {
        title: "Youtube",
        statsLink:
          "https://drive.google.com/drive/folders/10GcicZW0CezRSKkrkSQs3cY9k9kqmTBN?usp=share_link",
        link: "https://www.youtube.com/@DariaGraph",
        country: [
          {
            title: "Россия",
            percent: "68.8",
          },
          {
            title: "Украина",
            percent: "5.2",
          },
          {
            title: "Казахстан",
            percent: "3.9",
          },
          {
            title: "Беларусь",
            percent: "2.6",
          },
          {
            title: "Узбекистан",
            percent: "1.5",
          },
        ],
        slider: [
          {
            img: "stats-58-1.webp",
          },
          {
            img: "stats-59-1.webp",
          },
          {
            img: "stats-60-1.webp",
          },
          {
            img: "stats-61-1.webp",
          },
          {
            img: "stats-62-1.webp",
          },
          {
            img: "stats-63-1.webp",
          },
          {
            img: "stats-64-1.webp",
          },
          {
            img: "stats-65-1.webp",
          },
          {
            img: "stats-66-1.webp",
          },
          {
            img: "stats-67-1.webp",
          },
          {
            img: "stats-68-1.webp",
          },
          {
            img: "stats-69-1.webp",
          },
          {
            img: "stats-70-1.webp",
          },
        ],
        gender: {
          woman: "88.1",
          man: "11.4",
        },
        tags: [
          {
            text: "2.01 млн подписчиков",
          },
          {
            text: "600 тыс+ просмотров на каждом видео",
          },
        ],
      },

      instagram: {
        title: "Instagram",
        link: "https://www.instagram.com/cosmos_dg/",
        statsLink:
          "https://drive.google.com/drive/folders/109yGzmtOSiv9GsmehaEs1FnE_Ae6IYO9",
        country: [
          {
            title: "Россия",
            percent: "58",
          },
          {
            title: "Украина",
            percent: "8.4",
          },
          {
            title: "Казахстан",
            percent: "7.1",
          },
          {
            title: "Беларусь",
            percent: "5.8",
          },
        ],
        slider: [
          {
            img: "stats-51-1.webp",
          },
          {
            img: "stats-52-1.webp",
          },
          {
            img: "stats-53-1.webp",
          },
          {
            img: "stats-54-1.webp",
          },
          {
            img: "stats-55-1.webp",
          },
          {
            img: "stats-56-1.webp",
          },
          {
            img: "stats-57-1.webp",
          },
        ],
        gender: {
          woman: "94.9",
          man: "5",
        },
        tags: [
          {
            text: "672 тыс подписчиков",
          },
          {
            text: "200+ тыс просмотров reels",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "TikTok",
          socialLink: "https://www.tiktok.com/@dgraph",
          link: "https://drive.google.com/drive/folders/12lwoYfC5phXjP17WxiBTN1N7NWW-5uaa?usp=share_link",
        },
        {
          title: "Telegram",
          socialLink: "https://t.me/graphdg",
          link: "https://drive.google.com/drive/folders/10K-eOQ1ZFBhsRH8ZT6Ub34jkXlzTNtol",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.ru/daria_graph",
          link: "https://drive.google.com/drive/folders/1n6GS16pqnwvNsxafHiA37WM5J0bG0Akn",
        },
      ],
    },
  },
  {
    id: "doshik",
    bloggerName: "Даша Дошик",
    bloggerImage: "doshik.webp",
    social: [
      {
        link: "https://www.tiktok.com/@i_am_doshik1",
        title: "TikTok",
        subscribe: "22.3  млн.",
      },
      {
        link: "https://vk.com/i_am_doshik",
        title: "Вконтакте",
        subscribe: "682 тыс.",
      },
      {
        link: "https://t.me/iamdosh1k",
        title: "Telegram",
        subscribe: "93 тыс.",
      },
      {
        link: "https://www.youtube.com/channel/UCZALvgFkYIEHUYeIHjFr2pg",
        title: "Youtube",
        subscribe: "1.44 млн.",
      },
      {
        link: "https://www.instagram.com/i_am_doshik",
        title: "Instagram",
        subscribe: "1.9 млн.",
      },
    ],
    subtext:
      "Даша – блогер нового поколения. Она снимает разнообразный контент: макияжи, обзоры, танцы и скетчи. Даша попала в топ блогеров TikTok и VK благодаря тому, что она не боится экспериментировать с контентом. Даша часто снимается в YouTube-проектах и шоу.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Участвует в съёмках музыкальных клипов и популярных шоу",
        },
        {
          text: "Была амбассадором Samsung",
        },
        {
          text: "Хорошее чувство юмора",
        },
        {
          text: "Снимает парный контент",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Юмористические скетчи",
        },
        {
          text: "Челленджи",
        },
        {
          text: "Макияжи и преображения",
        },
        {
          text: "Танцы",
        },
        {
          text: "Обзоры и распаковки",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Даня Милохин",
          img: "bloggers-12.webp",
        },
        {
          text: "Лиза Анохина ",
          img: "bloggers-9.webp",
        },
        {
          text: "Дилара",
          img: "bloggers-5.webp",
        },
        {
          text: "Рахим Абрамов",
          img: "rakhim.webp",
        },
        {
          text: "Karina Kross",
          img: "bloggers-13.webp",
        },
        {
          text: "Дина Саева ",
          img: "bloggers-4.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "beautybomb.webp",
        },
        {
          img: "samsung.svg",
        },
        {
          img: "sberbank.svg",
        },
        {
          img: "acuvue.webp",
        },
        {
          img: "alfabank.svg",
        },
        {
          img: "logitech.svg",
        },
        {
          img: "sabo.svg",
        },
        {
          img: "gold.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "cover-1.webp",
          link: "https://www.youtube.com/watch?v=vtVTGX1LD8Q&ab_channel=%D0%A8%D0%9E%D0%A3%D0%9C%D0%B0%D0%BC%D0%B8%D0%BD%D0%BE%D0%B9%D0%9F%D0%BE%D0%B4%D1%80%D1%83%D0%B3%D0%B8",
          title: "«Шоу маминой подруги»",
          text: "2.2 млн просмотров",
        },
        {
          bg: "cover-2.webp",
          link: "https://www.youtube.com/watch?v=2cWb193BTWg",
          title: " Шоу «Ты меня знаешь»",
          text: "1,8 млн просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        link: "https://www.instagram.com/i_am_doshik",
        statsLink:
          "https://drive.google.com/drive/folders/1-Od6CaFwQ0KV99ewWGtFNyit3_DR-v4A",
        country: [
          {
            title: "Россия",
            percent: "41.8",
          },
          {
            title: "Украина",
            percent: "11.7",
          },
          {
            title: "Казахстан",
            percent: "9.2",
          },
          {
            title: "Беларусь",
            percent: "4.9",
          },
        ],
        slider: [
          {
            img: "stats-6.1.webp",
          },
          {
            img: "stats-6.2.webp",
          },
          {
            img: "stats-7.1.webp",
          },
          {
            img: "stats-8.7.webp",
          },
          {
            img: "stats-8-1.webp",
          },
          {
            img: "stats-8-2.webp",
          },
          {
            img: "stats-8-3.webp",
          },
          {
            img: "stats-8-4.webp",
          },
          {
            img: "stats-8-5.webp",
          },
          {
            img: "stats-8-6.webp",
          },
        ],
        gender: {
          woman: "63.1",
          man: "36.8",
        },
        tags: [
          {
            text: "1.9 млн подписчиков",
          },
          {
            text: "200+ тыс. просмотров Stories",
          },
        ],
      },
      youtube: {
        title: "Youtube",
        statsLink:
          "https://drive.google.com/drive/folders/1qun-V7ENd8KLFneR27_ylFzbhOeqjHpX",
        link: "https://www.youtube.com/@DOSH1K/videos",
        country: [
          {
            title: "Россия",
            percent: "68.8",
          },
          {
            title: "Украина",
            percent: "5.2",
          },
          {
            title: "Казахстан",
            percent: "3.9",
          },
          {
            title: "Беларусь",
            percent: "2.6",
          },
          {
            title: "Узбекистан",
            percent: "1.5",
          },
        ],
        slider: [
          {
            img: "doshik-youtube-1.webp",
          },
          {
            img: "doshik-youtube-2.webp",
          },
          {
            img: "doshik-youtube-3.webp",
          },
          {
            img: "doshik-youtube-4.webp",
          },
          {
            img: "doshik-youtube-5.webp",
          },
          {
            img: "doshik-youtube-6.webp",
          },
          {
            img: "doshik-youtube-7.webp",
          },
          {
            img: "doshik-youtube-8.webp",
          },
          {
            img: "doshik-youtube-9.webp",
          },
          {
            img: "doshik-youtube-10.webp",
          },
        ],
        gender: {
          woman: "72.8",
          man: "26.7",
        },
        tags: [
          {
            text: "2.01 млн подписчиков",
          },
          {
            text: "600 тыс+ просмотров на каждом видео",
          },
        ],
      },
      tikTok: {
        title: "TikTok",
        link: "https://www.tiktok.com/@i_am_doshik1",
        statsLink:
          "https://drive.google.com/drive/folders/1-NkkegVdUwqvYcScv9tc3FhqyV_v0jAD",
        country: [
          {
            title: "Россия",
            percent: "51",
          },
          {
            title: "Украина",
            percent: "14.6",
          },
          {
            title: "Казахстан",
            percent: "11.6",
          },
          {
            title: "США",
            percent: "10.7",
          },
          {
            title: "Беларусь",
            percent: "5",
          },
        ],
        slider: [
          {
            img: "tiktok-doshik-2.webp",
          },
          {
            img: "tiktok-doshik-2.webp",
          },
          {
            img: "tiktok-doshik-3.webp",
          },
          {
            img: "tiktok-doshik-4.webp",
          },
          {
            img: "tiktok-doshik-5.webp",
          },
          {
            img: "tiktok-doshik-6.webp",
          },
          {
            img: "tiktok-doshik-7.webp",
          },
        ],
        gender: {
          woman: "63",
          man: "37",
        },
        tags: [
          {
            text: "5+ млн – просмотров в неделю",
          },
          {
            text: "800+ тыс – лайков в неделю",
          },
        ],
      },
      vk: {
        title: "Вконтакте",
        statsLink:
          "https://drive.google.com/drive/folders/1Ip9O5GFArUJFqE6vxSb4LWCZuJGESfvS",
        link: "https://vk.com/i_am_doshik",
        country: [
          {
            title: "Россия",
            percent: "91.37",
          },

          {
            title: "Казахстан",
            percent: "1.74",
          },
          {
            title: "Беларусь",
            percent: "1.34",
          },
          {
            title: "Таиланд",
            percent: "0.63",
          },
        ],
        slider: [
          {
            img: "doshik-vk-1.webp",
          },
          {
            img: "doshik-vk-2.webp",
          },
          {
            img: "doshik-vk-3.webp",
          },
          {
            img: "doshik-vk-4.webp",
          },
        ],
        gender: {
          woman: "52",
          man: "48",
        },
        tags: [
          {
            text: "712 тыс подписчиков",
          },
          {
            text: "1 млн средний охват за день",
          },
        ],
      },
    },
  },
  {
    id: "rakhim",
    bloggerName: "Rakhim",
    bloggerImage: "rakhim.webp",
    social: [
      {
        link: "https://www.tiktok.com/@rahimabram",
        title: "TikTok",
        subscribe: "14.1 млн.",
      },
      {
        link: "https://www.instagram.com/rahimabramov/",
        title: "Instagram",
        subscribe: "6.1 млн.",
      },
      {
        link: "https://t.me/rakhimabramov",
        title: "Telegram",
        subscribe: "7 тыс.",
      },
      {
        link: "https://vk.com/rakhimabramov",
        title: "Вконтакте",
        subscribe: "445 тыс.",
      },
      {
        link: "https://youtube.com/@RakhimAbramov?feature=sharec",
        title: "Youtube",
        subscribe: "664 тыс.",
      },
    ],
    subtext:
      "Рахим — популярный хип-хопер, который активно ведёт свои соцсети. Рахим уже несколько лет не покидает российские топ-чарты: клипы Рахима на YouTube набрали десятки миллионов просмотров, а общее количество прослушиваний его треков в VK Music превысило 1 миллиард.  ",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Cам пишет музыку",
        },
        {
          text: "Жизнерадостный и весёлый",
        },
        {
          text: "Фокус на молодёжную аудиторию",
        },
        {
          text: "Подходит по стилю многим брендам",
        },
        {
          text: "Отличные показатели по прошлым кампаниям",
        },
        {
          text: "Высокий Engagement Rate",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Музыкальное творчество ",
        },
        {
          text: "Видеореакции на фанов",
        },
        {
          text: "Лайфстайл ",
        },
        {
          text: "Юмор ",
        },
      ],
    },
    music: {
      title: "Стиль контента",
      release: [
        {
          href: "https://youtu.be/MGWydGtq8lc",
          name: "“Fendi”",
          items: [
            {
              text: "VK, Boom: 500 000 000+ прослушиваний",
            },
            {
              text: "Spotify, Apple Music, Яндекс.Музыка, deezer: 250 000 000+",
            },
            {
              text: "YouTube: 100 000 000+ просмотров",
            },
            {
              text: "TikTok: 6 000 000+ клипов",
            },
          ],
        },
        {
          href: "https://youtu.be/jGYlhXgMq2c",
          name: "“Синий Lamborghini”",
          items: [
            {
              text: "VK, Boom: 400 000 000+ прослушиваний",
            },
            {
              text: "Spotify, Apple Music, Яндекс.Музыка, deezer: 100 000 000+",
            },
            {
              text: "YouTube: 7 000 000+ просмотров",
            },
            {
              text: " TikTok: 1 650 000+ клипов",
            },
          ],
        },
        {
          href: "https://youtu.be/-93noOqHujM",
          name: "“Milly Rock”",
          items: [
            {
              text: "VK, Boom: 7 500 000+ прослушиваний",
            },
            {
              text: "YouTube: 14 000 000+ просмотров",
            },
            {
              text: "TikTok: 115 000+ клипов",
            },
          ],
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Bella Poarch  ",
          img: "bloggers-17.webp",
        },
        {
          text: "Дина Саева",
          img: "bloggers-4.webp",
        },
        {
          text: "Лиза Анохина  ",
          img: "bloggers-9.webp",
        },
        {
          text: "Артур Бабич",
          img: "bloggers-26.webp",
        },
        {
          text: "Karina Kross",
          img: "bloggers-13.webp",
        },
        {
          text: "Jony",
          img: "jony.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "samsung.svg",
        },
        {
          img: "ali.webp",
        },
        {
          img: "beats.webp",
        },
        {
          img: "vk-2.svg",
        },
        {
          img: "pepsi.svg",
        },
        {
          img: "yandex.svg",
        },
        {
          img: "huawei.svg",
        },
        {
          img: "gold.svg",
        },
        {
          img: "paco.svg",
        },
        {
          img: "snikers.svg",
          filter: true,
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "rakhim-youtube-1.webp",
          link: "https://www.youtube.com/watch?v=gjMPwYqAtLs&pp=ygUZ0YDQsNGF0LjQvCDQsNCx0YDQsNC80L7Qsg%3D%3D&ab_channel=Gazgolder",
          title: "Вопрос ребром - Rakhim",
          text: "3 млн просмотров",
        },
        {
          bg: "rakhim-youtube-2.webp",
          link: "https://www.youtube.com/watch?v=dtWESTIcgLY",
          title: " РАХИМ АБРАМОВ показывает свои любимые вещи",
          text: "55 тыс. просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1iAeNp7pFl4pn_DFb-2IwphpH2rLuyawN?usp=share_link",
        link: "https://www.instagram.com/rahimabramov/",
        country: [
          {
            title: "Россия ",
            percent: "54",
          },
          {
            title: "Казахстан",
            percent: "11",
          },
          {
            title: "Украина",
            percent: "7.8",
          },
          {
            title: "Узбекистан",
            percent: "4.5",
          },
          {
            title: "Кыргызстан",
            percent: "2.7",
          },
        ],
        slider: [
          {
            img: "rakhim-1.webp",
          },
          {
            img: "rakhim-2.webp",
          },
          {
            img: "rakhim-3.webp",
          },
          {
            img: "rakhim-4.webp",
          },
          {
            img: "rakhim-5.webp",
          },
          {
            img: "rakhim-6.webp",
          },
          {
            img: "rakhim-7.webp",
          },
          {
            img: "rakhim-8.webp",
          },
          {
            img: "rakhim-9.webp",
          },
          {
            img: "rakhim-10.webp",
          },
          {
            img: "rakhim-11.webp",
          },
          {
            img: "rakhim-12.webp",
          },
        ],
        gender: {
          woman: "57",
          man: "43",
        },
        tags: [
          {
            text: "6.1 миллионов подписчиков в Instagram",
          },
          {
            text: "200+ тыс просмотров на stories",
          },
        ],
      },
      tikTok: {
        title: "TikTok",
        link: "https://www.tiktok.com/@rahimabram",
        statsLink:
          "https://drive.google.com/drive/folders/172rFHryDcj2bReRXdz-aT85HzqK_YdQU",
        country: [
          {
            title: "Казахстан",
            percent: "10.8",
          },
          {
            title: "США",
            percent: "10.6",
          },
          {
            title: "Узбекистан",
            percent: "8.6",
          },
          {
            title: "Кыргызстан",
            percent: "6.8",
          },
          {
            title: "Украина",
            percent: "4.3",
          },
        ],
        slider: [
          {
            img: "rakhim-t-1.webp",
          },
          {
            img: "rakhim-t-2.webp",
          },
          {
            img: "rakhim-t-3.webp",
          },
          {
            img: "rakhim-t-4.webp",
          },
          {
            img: "rakhim-t-5.webp",
          },
          {
            img: "rakhim-t-6.webp",
          },
          {
            img: "rakhim-t-7.webp",
          },
          {
            img: "rakhim-t-8.webp",
          },
          {
            img: "rakhim-t-9.webp",
          },
          {
            img: "rakhim-t-10.webp",
          },
        ],
        gender: {
          woman: "72",
          man: "28",
        },
        tags: [
          {
            text: "20+ млн подписчиков",
          },
          {
            text: "40+ млн просмотров в неделю",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "YouTube",
          socialLink: "https://youtube.com/@RakhimAbramov",
          link: "https://drive.google.com/drive/folders/19iFEH94hT8snkJyXFi_3PU9EvxKMzBdi",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.com/rakhimabramov",
          link: "https://drive.google.com/drive/folders/1HQr_pBvNqP4NAXn1o7yts_bRiSAQhvIC",
        },
      ],
    },
  },
  {
    id: "vlad",
    bloggerName: "Влад Хошин",
    bloggerImage: "vlad.webp",
    social: [
      {
        link: "https://www.tiktok.com/@vlad.hoshin",
        title: "TikTok",
        subscribe: "11,1 млн.",
      },
      {
        link: "https://www.instagram.com/vlad.hoshin/",
        title: "Instagram",
        subscribe: "1,7 млн.",
      },
      {
        link: "https://t.me/vladxhoshin",
        title: "Telegram",
        subscribe: "25 тыс.",
      },
      {
        link: "https://vk.com/hoshin.hoshin.hoshin",
        title: "Вконтакте",
        subscribe: "40 тыс.",
      },
    ],
    subtext:
      "Международная TikTok-звезда с совокупной аудиторией 13,5 млн подписчиков.<br><br>В своих социальных сетях Влад делится яркими моментами из жизни. Его лайфстайл близок молодым людям: он изучает языки, делает тату, проводит стримы на Twitch. Аккаунт Влада наполнен стилем, так как молодой парень очень интересуется fashion-индустрией и планирует создание своего бренда одежды.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Фокус на молодежную аудиторию",
        },
        {
          text: "Использует яркие образы",
        },
        {
          text: "Активно продвигает культуру K-POP",
        },
        {
          text: "Обожает татуировки и аниме",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Танцы и челленджи",
        },
        {
          text: "Лайфстайл",
        },
        {
          text: "Путешествия",
        },
        {
          text: "Забавные скетчи ",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Лиза Анохина ",
          img: "bloggers-9.webp",
        },
        {
          text: "Дина Саева",
          img: "bloggers-4.webp",
        },
        {
          text: "Ксюша Хоффман ",
          img: "bloggers-67.webp",
        },
        {
          text: "Артем Ватерфорк",
          img: "bloggers-68.webp",
        },
        {
          text: "Фая Feevuin",
          img: "bloggers-69.webp",
        },
        {
          text: "Чана",
          img: "bloggers-70.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "nyx.webp",
        },
        {
          img: "paco.svg",
        },
        {
          img: "vk-2.svg",
        },
        {
          img: "dorco.svg",
        },
        {
          img: "gold.svg",
        },
        {
          img: "vk-fest.svg",
        },
        {
          img: "pepsi.svg",
        },
        {
          img: "five.svg",
        },
        {
          img: "rivgosh.svg",
        },
        {
          img: "reebok.svg",
        },
        {
          img: "guess.svg",
        },
        {
          img: "laimon.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "cover-5.webp",
          link: "https://www.youtube.com/watch?v=97PKNWOJg0Y&ab_channel=%D0%90%D1%80%D0%B8%D0%BD%D0%B0%D0%94%D0%B0%D0%BD%D0%B8%D0%BB%D0%BE%D0%B2%D0%B0",
          title: "Шоу «Свидание вслепую",
          text: "3,8 млн просмотров",
        },
        {
          bg: "cover-6.webp",
          link: "https://www.youtube.com/watch?v=xDP7NbWqUrE",
          title: "Интервью Влада на YouTube-шоу «Пушка»",
          text: "920+ тыс. просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1-yW1aPDWgT0gZGUKlaJ-qpSzSXm6oKga",
        link: "https://www.instagram.com/vlad.hoshin/",
        country: [
          {
            title: "Бразилия",
            percent: "14.5",
          },
          {
            title: "Россия",
            percent: "12.3",
          },
          {
            title: "Филиппины",
            percent: "10.5",
          },
          {
            title: "Мексика",
            percent: "7.4",
          },
          {
            title: "США",
            percent: "6.3",
          },
        ],
        slider: [
          {
            img: "stats-28.webp",
          },
          {
            img: "stats-29.webp",
          },
          {
            img: "stats-30.webp",
          },
          {
            img: "stats-31.webp",
          },
        ],
        gender: {
          woman: "86",
          man: "14",
        },
        tags: [
          {
            text: "1 млн – средний охват",
          },
          {
            text: "250+ тыс просмотров на stories",
          },
        ],
      },
      tikTok: {
        title: "TikTok",
        link: "https://www.tiktok.com/@vlad.hoshin",
        statsLink:
          "https://drive.google.com/drive/folders/10-kev8fkGb4UhaUqKL98rOD1b8vxeLTq",
        country: [
          {
            title: "Филиппины",
            percent: "26",
          },
          {
            title: "США",
            percent: "16.5",
          },
          {
            title: "Россия",
            percent: "16",
          },
          {
            title: "Бразилия",
            percent: "14",
          },
          {
            title: "Мексика",
            percent: "10.1",
          },
        ],
        slider: [
          {
            img: "stats-32.webp",
          },
          {
            img: "stats-33.webp",
          },
          {
            img: "stats-34.webp",
          },
          {
            img: "stats-34.1.webp",
          },
          {
            img: "stats-34.2.webp",
          },
          {
            img: "stats-34.3.webp",
          },
          {
            img: "stats-34.4.webp",
          },
        ],
        gender: {
          woman: "84",
          man: "16",
        },
        tags: [
          {
            text: "5+ млн – просмотров в неделю",
          },
          {
            text: "800+ тыс – лайков в неделю",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "Telegram",
          socialLink: "https://t.me/vladxhoshin",
          link: "https://drive.google.com/drive/folders/1YrVq2JSvGUWDG36-5LCnJI-t28yWo8Ae?usp=share_link",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.com/hoshin.hoshin.hoshin",
          link: "https://drive.google.com/drive/folders/1Tb7bWkb-rvnUGp90QKPiq5PcidosKpTc",
        },
      ],
    },
  },
  {
    id: "vlad-a4",
    bloggerName: "Влад А4",
    bloggerImage: "vlad-a4.webp",
    social: [
      {
        link: "https://www.youtube.com/@A4a4a4a4",
        title: "Youtube",
        subscribe: "46.9 млн.",
      },
      {
        link: "https://www.instagram.com/a4omg/",
        title: "Instagram",
        subscribe: "7.01 млн.",
      },
      {
        link: "https://vk.com/a4",
        title: "Вконтакте",
        subscribe: "1.5 млн.",
      },
      {
        link: "https://t.me/A4omg",
        title: "Telegram",
        subscribe: "737 тыс.",
      },
      {
        link: "https://www.tiktok.com/@a4omg",
        title: "TikTok",
        subscribe: "11.4 млн.",
      },
    ],
    subtext:
      "Один из самых популярных блогеров среди молодого поколения, основатель объединения и YouTube-канала «A4». Влад также увлекается пением и выпустил несколько сольных песен, поучаствовал в фитах и записал свой собственный альбом. Творчество блогера - это позитив, юмор и креатив во всем. Благодаря действительно доброму и смешному контенту он привлекает не только младшее население, но также и более старшую аудиторию",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Блогер и исполнитель собственных песен",
        },
        {
          text: 'Владелец бизнеса "доставка пиццы от Влада А4"',
        },
        {
          text: "Создатель и владелец мерча А4",
        },
        {
          text: "Добрый парень с крутым чувством юмора",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Различные проверки",
        },
        {
          text: "Челленджи",
        },
        {
          text: "Юмористические видео",
        },
        {
          text: "Совместные видеоролики с командой",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Глент ",
          img: "bloggers-47.webp",
        },
        {
          text: "Егорик",
          img: "bloggers-58.webp",
        },
        {
          text: "Филипп Киркоров",
          img: "bloggers-66.webp",
        },
        {
          text: "Юлия Годунова",
          img: "bloggers-41.webp",
        },
        {
          text: "Егор Крид",
          img: "bloggers-43.webp",
        },
        {
          text: "Катя Адушкина",
          img: "bloggers-77.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "tinkoff.svg",
        },
        {
          img: "vk-2.svg",
        },
        {
          img: "yota.webp",
        },
        {
          img: "magnit.svg",
        },
        {
          img: "burger-king.svg",
          filter: true,
        },
        {
          img: "tvoye.svg",
        },
        {
          img: "colgate.svg",
        },
        {
          img: "dirol.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "a4-cover-1.webp",
          link: "https://www.youtube.com/watch?v=MvAQIeF0lmE&feature=youtu.be&ab_channel=%D0%A7%D0%B8%D0%BA%D0%B5%D0%BD%D0%9A%D0%B0%D1%80%D1%80%D0%B8",
          title: "Comment Out #18 / Влад Бумага х Егор Крид",
          text: "11 млн просмотров",
        },
        {
          bg: "a4-cover-2.webp",
          link: "https://www.youtube.com/watch?v=4Q1veIW57Hw&ab_channel=%D0%A2%D0%98%D0%9D%D0%A2%D0%9E%D0%9A",
          title: "А4 | ВЛАД БУМАГА против SHAZAM | Шоу ПОШАЗАМИМ",
          text: "8.3 млн просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1AFu94fakea0uJxv3sKMtKNbxbeeRFgDz",
        link: "https://www.instagram.com/a4omg/",
        country: [
          {
            title: "Россия",
            percent: "52.1",
          },
          {
            title: "Украина",
            percent: "16",
          },
          {
            title: "Казахстан",
            percent: "7.5",
          },
          {
            title: "Беларусь",
            percent: "4.6",
          },
          {
            title: "Узбекистан",
            percent: "2.1",
          },
        ],
        slider: [
          {
            img: "a4-insta-3.webp",
          },
          {
            img: "a4-insta-4.webp",
          },
          {
            img: "a4-insta-6.webp",
          },
          {
            img: "a4-insta-1.webp",
          },
          {
            img: "a4-insta-2.webp",
          },
          {
            img: "a4-insta-5.webp",
          },
        ],
        gender: {
          woman: "50",
          man: "50",
        },
        tags: [
          {
            text: "7.1 млн подписчиков",
          },
          {
            text: "600 тыс+ просмотров на Stories",
          },
        ],
      },
      youtube: {
        slide: true,
        title: "Youtube",
        link: "https://www.youtube.com/@A4a4a4a4",
        statsLink:
          "https://drive.google.com/drive/folders/1-fooMf9ulbB_1FS71cvNZydrB8HbLM3i?usp=share_link",
        country: [
          {
            title: "Россия",
            percent: "58.7",
          },
          {
            title: "Украина",
            percent: "14.5",
          },
          {
            title: "Казахстан",
            percent: "10.5",
          },
          {
            title: "Беларусь",
            percent: "4.5",
          },
          {
            title: "Киргизия",
            percent: "2.9",
          },
        ],
        slider: [
          {
            img: "a4-youtube1.webp",
          },
          {
            img: "a4-youtube2.webp",
          },
          {
            img: "a4-youtube3.webp",
          },
        ],
        gender: {
          woman: "48",
          man: "52",
        },
        tags: [
          {
            text: "7.1 млн подписчиков",
          },
          {
            text: "600 тыс+ просмотров на Stories",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "Telegram",
          socialLink: "https://t.me/A4omg",
          link: "https://drive.google.com/drive/folders/10LBnK8OaGNPpz03tqbKpM5TN0XWvs357?usp=share_link",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.com/a4",
          link: "https://drive.google.com/drive/folders/1UdVTWMrthWEOX-pVIemm40rAsB6PE7lf?usp=share_link",
        },
      ],
    },
  },
  {
    id: "amina",
    bloggerName: "Амина Тендерлибае ",
    bloggerImage: "amina.webp",
    social: [
      {
        link: "https://instagram.com/tenderlybae",
        title: "Instagram",
        subscribe: "3.9 млн.",
      },
      {
        link: "https://www.tiktok.com/@tenderlybaemusic",
        title: "TikTok",
        subscribe: "15.7 млн.",
      },
      {
        link: "https://m.youtube.com/channel/UCkdRAPVGvnLjDC0L88Qt0YQ?ysclid=llnluv5mgn118089210",
        title: "Youtube",
        subscribe: "1.11 млн.",
      },
      {
        link: "https://twitch.tv/tenderlybae",
        title: "Twitch",
        subscribe: "1.3 млн.",
      },
      {
        link: "https://vk.com/tenderlybae",
        title: "Вконтакте",
        subscribe: "709.2 тыс.",
      },
      {
        link: "https://t.me/plsbetenderly",
        title: "Telegram",
        subscribe: "254 тыс.",
      },
    ],
    subtext:
      "Амина Тендерлибае — известный российский стример, блогер и певица. Девушка часто появляется гостем, а иногда и ведущей в различных популярных  шоу. Помимо блогерский деятельности, Амина занимается музыкой, а с выходом клипа на песню «Ломай», девушка решила показать зрителям и своё лицо. Ранее блогер скрывала себя за маской, что являлось частью её образа и придало ей большую популярность.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Чуткая девушка ",
        },
        {
          text: "Исполнительница собственных треков ",
        },
        {
          text: "Принимает участие в популярных шоу ",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Онлайн трансляции ",
        },
        {
          text: "Лайфстайл видео",
        },
        {
          text: "Короткие видео под популярные треки",
        },
        {
          text: "Коллаборации с другими блогерами",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Adel Veigel",
          img: "bloggers-111.webp",
        },
        {
          text: "Дилара",
          img: "bloggers-5.webp",
        },
        {
          text: "Бустер",
          img: "bloggers-29.webp",
        },
        {
          text: "Шайни",
          img: "bloggers-110.webp",
        },
        {
          text: "Молодой Платон",
          img: "bloggers-109.webp",
        },
        {
          text: "Inst_Rinna",
          img: "instrinna.webp",
        },
        {
          text: "Soda Luv ",
          img: "bloggers-112.webp",
        },
        {
          text: "Akyuliych",
          img: "akyuliych.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "gold.svg",
        },
        {
          img: "gj.svg",
        },
        {
          img: "mts.svg",
        },
        {
          img: "sinergia.svg",
        },
        {
          img: "vshe.svg",
          filter: true,
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "amina-cover-1.webp",
          link: "https://youtu.be/Oean43JkQKg?si=O5AApn4JU0VhCmgI",
          title:
            "Tenderlybae — Жизнь без маски, Увеличение Груди, Twitch Забанят, Национализм (Подкаст Джарахова №1)",
          text: "1.3 млн просмотров",
        },
        {
          bg: "amina-cover-2.webp",
          link: "https://youtu.be/IfXhGa1klw8?si=mj0TxBChLYeVLYOf",
          title:
            "Мама Амины Tenderlybae одобряет ее увеличение груди. Шоу Кросс и Каграманова. Кто твой подписчик?",
          text: "3.8 млн просмотров",
        },
      ],
    },
    statistic: {
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1-TvJW672796nnKteSl3EUcvqmuoEwgrv?usp=share_link",
        link: "https://instagram.com/tenderlybae",
        country: [
          {
            title: "Россия",
            percent: "63.9",
          },
          {
            title: "Украина",
            percent: "11.1",
          },
          {
            title: "Казахстан",
            percent: "5.2",
          },
          {
            title: "Беларусь",
            percent: "3.6",
          },
          {
            title: "Узбекистан",
            percent: "1.7",
          },
        ],
        slider: [
          {
            img: "amina-inst-1.webp",
          },
          {
            img: "amina-inst-2.webp",
          },
          {
            img: "amina-inst-3.webp",
          },
          {
            img: "amina-inst-4.webp",
          },
          {
            img: "amina-inst-5.webp",
          },
          {
            img: "amina-inst-6.webp",
          },
          {
            img: "amina-inst-7.webp",
          },
        ],
        gender: {
          woman: "53.7",
          man: "46.3",
        },
        tags: [
          {
            text: "3.9 млн. подписчиков",
          },
          {
            text: "250 тыс+ просмотров на stories",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "Telegram",
          socialLink: "https://t.me/plsbetenderly",
          link: "https://drive.google.com/drive/folders/1umSyvL3bJzFTEwz5DqMdEtWIhfIoVr-9?usp=share_link",
        },
        {
          title: "Вконтакте",
          socialLink: "https://vk.com/tenderlybae",
          link: "https://drive.google.com/drive/folders/1O8k4K9vyIdZubsa37wWaHGoB66MbFdv9?usp=share_link",
        },
        {
          title: "Twitch",
          socialLink: "https://m.twitch.tv/tenderlybae",
          link: "https://drive.google.com/drive/folders/1mOb3pbsvVkoHvdcTm7TzwzBoy98ASpwr?usp=share_link",
        },
      ],
    },
  },
  {
    id: "nysha",
    bloggerName: "Нюша ",
    poster: "nysha.webp",
    video: "3117912fd62a57fb049f.mp4",
    social: [
      {
        link: "https://instagram.com/nyusha_nyusha",
        title: "Instagram",
        subscribe: "5 млн.",
      },
      {
        link: "https://www.tiktok.com/@nyusha_nyusha",
        title: "TikTok",
        subscribe: "1.6 млн.",
      },
      {
        link: "https://t.me/nyushateam",
        title: "Telegram",
        subscribe: "25 тыс.",
      },
      {
        link: "https://vk.com/nyusha_official",
        title: "Вконтакте",
        subscribe: "1.2 млн.",
      },
      {
        link: "https://www.youtube.com/@NYUSHAmusic/videos",
        title: "Youtube",
        subscribe: "578 тыс.",
      },
    ],
    subtext:
      "Нюша — легенда российской поп-музыки, а сегодня ещё и популярная инста-мама и семейный блогер. Она делится с подписчикам своей жизнью, а ещё постоянно показывает своих замечательных детей.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Артист, достигшая высокого уровня в шоу-бизнесе",
        },
        {
          text: "Транслирует свою обычную жизнь",
        },
        {
          text: "Снимает видеоролики о семье",
        },
        {
          text: "Продолжает выпускать треки",
        },
        {
          text: "Поет с 5 лет",
        },
        {
          text: "Участвует в различных съемках от музыкальных клипов до популярных шоу",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Семейный контент",
        },
        {
          text: "Развлекательные видео",
        },
        {
          text: "Экспертные видео в роли мамы",
        },
        {
          text: "Юмористические фото и видео",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Егор Крид",
          img: "bloggers-43.webp",
        },
        {
          text: "Артем Качер",
          img: "bloggers-82.webp",
        },
        {
          text: "Филипп Киркоров",
          img: "bloggers-66.webp",
        },
        {
          text: "Arash",
          img: "bloggers-83.webp",
        },
        {
          text: "ЛСП",
          img: "bloggers-64.webp",
        },
        {
          text: "Сергей Лазарев",
          img: "bloggers-65.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "coca.svg",
        },
        {
          img: "five.svg",
        },
        {
          img: "nivea.svg",
          filter: true,
        },
        {
          img: "gj.svg",
        },
        {
          img: "nurofen.svg",
        },
        {
          img: "gold.svg",
        },
        {
          img: "miralin.webp",
        },
        {
          img: "avon.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "nysha-1.webp",
          link: "https://www.youtube.com/watch?v=jgX_LEQryEM&ab_channel=FAMETIMETV",
          title:
            "Нюша - О песне с Егором Кридом, реакции мужа и звонке из Comment Out / Караоке",
          text: "1 млн просмотров",
        },
        {
          bg: "nysha-2.webp",
          link: "https://www.youtube.com/watch?v=20x4BzmiY4I&ab_channel=%D0%96%D0%90%D0%A0%D0%90TV",
          title:
            "Nyusha рассказала о своем новом треке и коллаборации с Егором Кридом /// Жара в эфире",
          text: "4.6 тыс. просмотров",
        },
      ],
    },
    statistic: {
      vk: {
        slide: true,
        title: "Вконтакте",
        statsLink:
          "https://drive.google.com/drive/folders/12kN-HzTjsIZHGTwFi_CooEqS27rwStae",
        link: "https://vk.com/nyusha_official",
        country: [
          {
            title: "Россия",
            percent: "89.62",
          },

          {
            title: "Казахстан",
            percent: "1.63",
          },
          {
            title: "Беларусь",
            percent: "1.34",
          },
          {
            title: "Германия",
            percent: "0.87",
          },
        ],
        slider: [
          {
            img: "nysha-vk-1.webp",
          },
          {
            img: "nysha-vk-2.webp",
          },
          {
            img: "nysha-vk-3.webp",
          },
          {
            img: "nysha-vk-4.webp",
          },
          {
            img: "nysha-vk-5.webp",
          },
          {
            img: "nysha-vk-6.webp",
          },
          {
            img: "nysha-vk-7.webp",
          },
          {
            img: "nysha-vk-8.webp",
          },
          {
            img: "nysha-vk-9.webp",
          },
          {
            img: "nysha-vk-10.webp",
          },
          {
            img: "nysha-vk-11.webp",
          },
          {
            img: "nysha-vk-12.webp",
          },
        ],
        gender: {
          woman: "59",
          man: "41",
        },
        tags: [
          {
            text: "1.2 млн. подписчиков",
          },
          {
            text: "500 тыс. средний охват за день",
          },
        ],
      },
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1LqMFDvT1aHdYtJOtguXPYdNoz7g9BjmA?usp=share_link",
        link: "https://instagram.com/nyusha_nyusha",
        country: [
          {
            title: "Россия",
            percent: "60",
          },
          {
            title: "Казахстан",
            percent: "8.8",
          },
          {
            title: "Беларусь",
            percent: "5.4",
          },
          {
            title: "Украина",
            percent: "4.3",
          },
        ],
        slider: [
          {
            img: "nysha-5.webp",
          },
          {
            img: "nysha-6.webp",
          },
          {
            img: "nysha-3.webp",
          },
          {
            img: "nysha-4.webp",
          },
          {
            img: "nysha-8.webp",
          },
          {
            img: "nysha-7.webp",
          },
        ],
        gender: {
          woman: "87.5",
          man: "12.4",
        },
        tags: [
          {
            text: "5 млн. подписчиков",
          },
          {
            text: "250 тыс+ просмотров на stories",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "YouTube",
          socialLink: "https://www.youtube.com/@NYUSHAmusic/",
          link: "https://drive.google.com/drive/folders/1-Y13TgM-9e1CgQahwEK3xErn19bjo5xE?usp=share_link",
        },
        {
          title: "Telegram",
          socialLink: "https://t.me/nyushateam",
          link: "https://t.me/kalishevskaya",
        },
      ],
    },
  },
  {
    id: "instrinna",
    bloggerName: "Inst_Rinna ",
    bloggerImage: "instrinna.webp",

    social: [
      {
        link: "https://instagram.com/inst_rinna",
        title: "Instagram",
        subscribe: "1.1 млн.",
      },
      {
        link: "https://www.tiktok.com/@inst_rinna",
        title: "TikTok",
        subscribe: "3.1 млн.",
      },
      {
        link: "https://vk.com/inst_rinnaaaa",
        title: "Вконтакте",
        subscribe: "100 тыс.",
      },
      {
        link: "https://t.me/inst_rinnaa",
        title: "Telegram",
        subscribe: "144 тыс.",
      },
      {
        link: "https://www.youtube.com/@inst_rinnaa",
        title: "Youtube",
        subscribe: "26 тыс.",
      },
    ],
    subtext:
      "Рина набрала большую популярность в VK и TikTok благодаря своему танцевальному таланту. <br><br>В соцсетях Рина выставляет эстетичный лайфстайл-контент: путешествия, бьюти и танцы. На её страницах сочетаются драйв, спорт, красота и искусство. Талантливая в танцах и музыке, она приковывает внимание не только навыками, но и неповторимой энергетикой. Сейчас осваивает диджеинг.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Популярный блогер в TikTok и VK ",
        },
        {
          text: "Профессионально занимается танцами",
        },
        {
          text: "Устраивает мастер-классы",
        },
        {
          text: "Занимается благотворительностью",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Танцевальные видеоролики",
        },
        {
          text: "Эстетические видео и фото",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Дилара",
          img: "bloggers-5.webp",
        },
        {
          text: "Юлия Годунова",
          img: "bloggers-41.webp",
        },
        {
          text: "Егор Крид",
          img: "bloggers-43.webp",
        },
        {
          text: "Бустер",
          img: "bloggers-29.webp",
        },
        {
          text: "Даня Милохин",
          img: "bloggers-12.webp",
        },
        {
          text: "Инстасамка",
          img: "bloggers-31.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "sberbank.svg",
        },
        {
          img: "sberbank.svg",
        },
        {
          img: "vk-2.svg",
        },
        {
          img: "beautybomb.webp",
        },
        {
          img: "sabo.svg",
        },
        {
          img: "tinkoff.svg",
        },
        {
          img: "mts.svg",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "rina-3.webp",
          link: "https://www.youtube.com/watch?v=p1M_vDhfzbA&t=13s&ab_channel=GQRussia",
          title: "Как хорошо MAYOT знает свою девушку ИНСТ РИНУ",
          text: "1,7 млн просмотров",
        },
        {
          bg: "rina-4.webp",
          link: "https://www.youtube.com/watch?v=CCEPxRYPVgc&pp=ygUR0LjQvdGB0YIg0YDQuNC90LA%3D&ab_channel=%D0%A2%D0%9D%D0%A2MUSIC",
          title:
            "ИНСТ РИНА | про Гаврилину, Милохина и Инстасамку | СКОЛЬКО ДАШЬ?",
          text: "99 тыс. просмотров",
        },
      ],
    },
    statistic: {
      vk: {
        slide: true,
        title: "Вконтакте",
        statsLink:
          "https://drive.google.com/drive/folders/1O5kqaPUM54m3sldNpJQaAh_UPyvkMLfz",
        link: "https://vk.com/inst_rinnaaaa",
        country: [
          {
            title: "Россия",
            percent: "90.41",
          },

          {
            title: "Таиланд",
            percent: "1.42",
          },
          {
            title: "Казахстан",
            percent: "1.37",
          },
        ],
        slider: [
          {
            img: "rina-vk-1.webp",
          },
          {
            img: "rina-vk-2.webp",
          },
          {
            img: "rina-vk-3.webp",
          },
          {
            img: "rina-vk-4.webp",
          },
        ],
        gender: {
          woman: "68",
          man: "32",
        },
        tags: [
          {
            text: "102 тыс подписчиков",
          },
          {
            text: "130 тыс+ средний охват на постах",
          },
        ],
      },
      instagram: {
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1-kH9z9u1y3M8WXM45nVX5PbSMvGYp5JH?usp=share_link",
        link: "https://instagram.com/inst_rinna",
        country: [
          {
            title: "Россия",
            percent: "68.4",
          },
          {
            title: "Украина",
            percent: "6.8",
          },
          {
            title: "Беларусь",
            percent: "4.6",
          },
          {
            title: "Казахстан",
            percent: "4.5",
          },
        ],
        slider: [
          {
            img: "rina-1.webp",
          },
          {
            img: "rina-2.webp",
          },
          {
            img: "rina-5.webp",
          },
          {
            img: "rina-6.webp",
          },
          {
            img: "rina-7.webp",
          },
          {
            img: "rina-8.webp",
          },
          {
            img: "rina-9.webp",
          },
          {
            img: "rina-10.webp",
          },
        ],
        gender: {
          woman: "80",
          man: "20",
        },
        tags: [
          {
            text: "1.1 млн подписчиков ",
          },
          {
            text: "200 тыс+ в среднем просмотров Reels ",
          },
        ],
      },
    },
    socialMedia: {
      social: [
        {
          title: "YouTube",
          socialLink: "https://www.youtube.com/@inst_rinnaa",
          link: "https://t.me/kalishevskaya",
        },
        {
          title: "Telegram",
          socialLink: "https://t.me/inst_rinnaa",
          link: "https://drive.google.com/drive/folders/1-gg_Nyh3yIH5buWkPXfcaNX6llwAPWNJ?usp=share_link",
        },
      ],
    },
  },
  {
    id: "nikita",
    bloggerName: "Никита Ефремов",
    bloggerImage: "nikita.webp",

    social: [
      {
        link: "https://instagram.com/nefremov",
        title: "Instagram",
        subscribe: "46 тыс.",
      },
      {
        link: "https://www.tiktok.com/@nikitaefremov",
        title: "TikTok",
        subscribe: "197.7 тыс.",
      },
      {
        link: "https://t.me/nikitaefremovfam",
        title: "Telegram",
        subscribe: "29.8 тыс.",
      },
      {
        link: "https://vk.com/ne.nikitaefremov",
        title: "Вконтакте",
        subscribe: "34.3 тыс.",
      },
      {
        link: "https://www.youtube.com/@nikitaefremov_me",
        title: "Youtube",
        subscribe: " 344 тыс.",
      },
    ],
    subtext:
      "Никита — фанат сникер-культуры: он снимает обзоры на одежду и кроссовки, а в собственном шоу Никита помогает звездам подобрать самую модную одежду и кроссовки. У Никиты есть свой бизнес: он реселлер кроссовок №1 в СНГ.",
    facts: {
      title: "Факты",
      items: [
        {
          text: "Рессейлер №1 в России и СНГ ",
        },
        {
          text: "Отличное чувство юмора и упорство",
        },
        {
          text: "Активно развивается в направлении блогинга",
        },
      ],
    },
    style: {
      title: "Стиль контента",
      items: [
        {
          text: "Влоги",
        },
        {
          text: "Обзоры последних новостей мира моды",
        },
        {
          text: "Новый формат интервью",
        },
      ],
    },
    collaboration: {
      title: "Коллаборации",
      items: [
        {
          text: "Бустер",
          img: "bloggers-29.webp",
        },
        {
          text: "Тимати",
          img: "bloggers-37.webp",
        },
        {
          text: "Нурлан Сабуров",
          img: "bloggers-35.webp",
        },
        {
          text: "Субо",
          img: "bloggers-36.webp",
        },
        {
          text: "Никита Ефремов",
          img: "bloggers-33.webp",
        },
        {
          text: "Дина Саева ",
          img: "bloggers-4.webp",
        },
        {
          text: "Эльдар Джарахов  ",
          img: "bloggers-18.webp",
        },
        {
          text: "Моргеншттерн",
          img: "bloggers-34.webp",
        },
        {
          text: "Инстасамка",
          img: "bloggers-31.webp",
        },
        {
          text: "Джиган",
          img: "bloggers-30.webp",
        },
        {
          text: "Михаил Литвин",
          img: "bloggers-32.webp",
        },
      ],
    },
    brands: {
      title: "Бренды",
      items: [
        {
          img: "yandex.svg",
        },
        {
          img: "alfabank.svg",
        },
        {
          img: "aviasales.svg",
        },
        {
          img: "grand.webp",
        },
      ],
    },
    media: {
      youtube: [
        {
          bg: "youtube-cover-2.webp",
          link: "https://www.youtube.com/watch?v=pBD4Wm8KmIk&ab_channel=%D0%95%D1%81%D1%82%D1%8C%D1%80%D0%B0%D0%B7%D0%B3%D0%BE%D0%B2%D0%BE%D1%80",
          title:
            "Миллионы на перепродаже кроссовок | Как устроен бренд NE | Сколько платишь блогерам?",
          text: "271 тыс. просмотров ",
        },
        {
          bg: "youtube-cover-1.webp",
          link: "https://www.youtube.com/watch?v=r_Vt3W8ED7o&pp=ygUb0L3QuNC60LjRgtCwINC10YTRgNC10LzQvtCy",
          title:
            "Никита Ефремов – Дубай с Моргеном, Литвиным, Джиганом и бизнес на две страны",
          text: "96 тыс. просмотров ",
        },
      ],
    },
    statistic: {
      youtube: {
        slide: true,
        title: "Instagram",
        statsLink:
          "https://drive.google.com/drive/folders/1gm0KLg7jNv9ykqx29alRsyhDkq_uFmg_?usp=share_link",
        link: "https://www.youtube.com/@nikitaefremov_me",
        country: [
          {
            title: "Россия",
            percent: "79.5",
          },
          {
            title: "Украина",
            percent: "3.2",
          },
          {
            title: "Казахстан",
            percent: "2.4",
          },
          {
            title: "Беларусь",
            percent: "1.9",
          },
          {
            title: "Узбекистан",
            percent: "1.5",
          },
        ],
        slider: [
          {
            img: "nikita-1.webp",
          },
          {
            img: "nikita-2.webp",
          },
          {
            img: "nikita-3.webp",
          },
          {
            img: "nikita-4.webp",
          },
          {
            img: "nikita-5.webp",
          },
          {
            img: "nikita-6.webp",
          },
          {
            img: "nikita-7.webp",
          },
        ],
        gender: {
          woman: "84.1",
          man: "15.9",
        },
        tags: [
          {
            text: "344 тыс. млн подписчиков ",
          },
          {
            text: "1 млн. в среднем просмотров ",
          },
        ],
      },
    },
  },
];

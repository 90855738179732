import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { INFLUENSER_PERSON } from "../../shared/constants";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import useScrollListener from "../../components/hook/useScrollListener";
import Fancybox from "../../components/fancybox";
import { Swiper, SwiperSlide } from "swiper/react";
import CircularProgress from "@material-ui/core/CircularProgress";

import "swiper/swiper.min.css";

export const InfluencerPerson = () => {
  const [navClassList, setNavClassList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const scroll = useScrollListener();

  useEffect(() => {
    const _classList = [];

    scroll.y > 150 && scroll.y - scroll.lastY > 0
      ? _classList.push("hide studio-stats__price")
      : _classList.push("studio-stats__price");

    setNavClassList(_classList);
  }, [scroll.y, scroll.lastY]);

  const { id } = useParams();
  const person = INFLUENSER_PERSON.find((el) => el.id === id);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, [isLoading]);

  if (isLoading)
    return (
      <div className="peaches-calendar">
        <div className="container">
          <div className="peaches-calendar__main bg-gray">
            <CircularProgress style={{ color: "black" }} />
          </div>
        </div>
      </div>
    );

  return (
    <section className="studio-stats">
      <div className="container">
        <div className="studio-stats__content">
          <div className="studio-stats__main">
            <div className="studio-stats__picture ">
              {person.bloggerImage ? (
                <img
                  className="picture anastasile"
                  src={`https://platform.peaches.studio/assets/images/${person.bloggerImage}`}
                  alt="liza"
                />
              ) : (
                <video
                  className="lazy-load"
                  autoPlay="autoplay"
                  poster={`https://platform.peaches.studio/assets/images/${person.poster}`}
                  muted
                  playsInline
                  nocontrols
                  controlslist="nodownload"
                  loop="loop"
                >
                  <source
                    src={`https://platform.peaches.studio/assets/images/${person.video}`}
                    type="video/mp4"
                  />{" "}
                  Sorry, your browser doesn't support embedded videos.
                </video>
              )}
              <div className="studio-stats__info">
                <h1 className="studio-stats__title">{person.bloggerName}</h1>
                <div className="studio-stats__button">
                  <div className="btn-outline">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span data-label="Прайс-лист">Прайс-лист</span>
                    </a>
                  </div>
                  <img
                    className="lazy-load"
                    src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                    alt="arrow"
                  />
                </div>
              </div>
            </div>

            <div className="studio-stats__wrap">
              <ul className="studio-stats__inlist">
                {person.social.map((item, index) => {
                  return (
                    <li>
                      <a
                        href={item.link}
                        target="_blank"
                        rel="noreferrer"
                        key={index}
                      >
                        <span>(0{index + 1})</span>
                        <div className="studio-stats__inner">
                          <p>{item.title}</p>
                          <span>{item.subscribe}</span>
                        </div>
                      </a>
                    </li>
                  );
                })}
              </ul>
              <div
                className="studio-stats__text"
                dangerouslySetInnerHTML={{
                  __html: person.subtext,
                }}
              ></div>
              <div className={navClassList.join(" ")}>
                <div className="studio-stats__price-title">Прайс-лист</div>
                <div className="studio-stats__button">
                  <div className="btn-outline">
                    <a
                      href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit?usp=sharing"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span data-label="Перейти">Перейти</span>
                    </a>
                    <img
                      className="lazy-load"
                      src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                      alt="arrow"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="studio-stats__row">
            {person.facts.title && (
              <div className="studio-stats__box">
                <h4>{person.facts.title}</h4>
                <ul>
                  {person.facts.items.map((item, index) => {
                    return <li key={index}>{item.text}</li>;
                  })}
                </ul>
              </div>
            )}
            {person.style.title && (
              <div className="studio-stats__box">
                <h4>{person.style.title}</h4>
                <ul>
                  {person.style.items.map((item, index) => {
                    return <li key={index}>{item.text}</li>;
                  })}
                </ul>
              </div>
            )}
            {person.music && (
              <div className="studio-stats__box collaboration">
                <h4>{person.music.title}</h4>
                {person.music.release.map((item) => {
                  return (
                    <>
                      <h3>
                        <a href={item.href} target="_blank" rel="noreferrer">
                          {item.name}
                        </a>
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </h3>
                      <ul>
                        {item.items.map((item, index) => {
                          return <li key={index}>{item.text}</li>;
                        })}
                      </ul>
                    </>
                  );
                })}
              </div>
            )}
            {person.music && (
              <div className="studio-stats__box collaboration"></div>
            )}

            <div className="studio-stats__box collaboration">
              <h4>Коллаборации</h4>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={"auto"}
                navigation
                observer={"true"}
                observeslidechildren={"true"}
                updateOnWindowResize={"true"}
                freeMode={"true"}
                className=" studio-stats__bloggers"
                scrollbar={{ draggable: true }}
                breakpoints={{
                  320: {
                    spaceBetween: 16,
                    speed: 700,
                    observeParents: true,
                    observeSlideChildren: true,
                    slidesPerView: "auto",
                  },
                  767: {
                    slidesPerView: 0,
                    spaceBetween: 0,
                  },
                }}
              >
                {person.collaboration.items.map((item, index) => {
                  return (
                    <SwiperSlide>
                      {" "}
                      <img
                        className="lazy-load"
                        src={`https://platform.peaches.studio/assets/images/${item.img}`}
                        alt="bloggers"
                      />
                      <p>{item.text} </p>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
            <div className="studio-stats__box collaboration">
              <h4>{person.brands.title}</h4>
              <ul className="brand">
                {person.brands.items.map((item, index) => {
                  return (
                    <li key={index}>
                      <img
                        src={`https://platform.peaches.studio/assets/images/company/${item.img}`}
                        alt="bloggers"
                        className={item.filter && "f-black"}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="studio-stats__section">
            {person.statistic.youtube && (
              <div
                className="studio-stats__social fade-in"
                data-scroll="data-scroll"
              >
                <div className="studio-stats__social-main">
                  <div className="studio-stats__social-info">
                    <h2>{person.statistic.youtube.title}</h2>
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.youtube.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            data-label={`Открыть ${person.statistic.youtube.title}`}
                          >
                            Открыть {person.statistic.youtube.title}
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href={person.statistic.youtube.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <ul className="studio-stats__nav" data-scroll="data-scroll">
                    {person.statistic.youtube.country.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="studio-stats__inner">
                            <p>{item.title} </p>
                            <span>{item.percent}%</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="studio-stats__gender">
                    <div className="studio-stats__gender-item">
                      <span>Женщины</span>
                      <div className="studio-stats__line woman" />
                      <span>{person.statistic.youtube.gender.woman}%</span>
                    </div>
                    <div
                      className="studio-stats__gender-item"
                      style={{
                        flex: `0 0 ${person.statistic.youtube.gender.man}%`,
                      }}
                    >
                      <span>Мужчины</span>
                      <div className="studio-stats__line man" />
                      <span>{person.statistic.youtube.gender.man}%</span>
                    </div>
                  </div>
                  {}
                  <ul className="studio-stats__tag">
                    {person.statistic.youtube.tags.map((item, index) => {
                      return <li key={index}>{item.text}</li>;
                    })}
                  </ul>
                </div>
                <div
                  className="studio-stats__inmain fade-in"
                  data-scroll="data-scroll"
                >
                  <div className="studio-stats__social-info">
                    <h2>Статистика</h2>
                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.youtube.statsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Смотреть статистику">
                            Смотреть статистику
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href="https://drive.google.com/drive/folders/1-yW1aPDWgT0gZGUKlaJ-qpSzSXm6oKga"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16}
                    slidesPerView={person.statistic.youtube.slide ? "1" : "3"}
                    observer={"true"}
                    speed={"700"}
                    freeMode={"true"}
                    className="studio-stats__slider"
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      320: {
                        spaceBetween: 10,
                        slidesPerView: 2,
                      },

                      567: {
                        spaceBetween: 16,
                        slidesPerView: person.statistic.youtube.slide ? 1 : 3,
                      },
                    }}
                  >
                    {person.statistic.youtube.slider.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Fancybox
                            options={{
                              idle: false,
                              compact: false,
                              dragToClose: false,
                              transitionEffect: "fade",
                              animated: true,
                              showClass: "f-fadeSlowIn",
                              hideClass: false,
                              Carousel: {
                                infinite: true,
                              },

                              Images: {
                                zoom: false,
                                Panzoom: {
                                  maxScale: 1.1,
                                },
                              },

                              Toolbar: {
                                absolute: true,
                                display: {
                                  left: [],
                                  middle: [],
                                  right: ["close"],
                                },
                              },

                              Thumbs: {
                                type: "classic",
                                Carousel: {
                                  axis: "x",

                                  slidesPerPage: 2,
                                  Navigation: true,
                                  center: true,
                                  fill: true,
                                  dragFree: true,

                                  breakpoints: {
                                    "(min-width: 640px)": {
                                      axis: "y",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <a
                              data-fancybox="gallery"
                              href={`https://platform.peaches.studio/assets/images/${item.img}`}
                            >
                              <img
                                src={`https://platform.peaches.studio/assets/images/${item.img}`}
                                alt="bloggers"
                              />
                              <div className="studio-stats__zoom">
                                <svg
                                  tabIndex={-1}
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m19.5 4.5-15 15M4.5 4.5l15 15" />
                                </svg>
                              </div>
                            </a>
                          </Fancybox>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
            {person.statistic.instagram && (
              <div
                className="studio-stats__social fade-in"
                data-scroll="data-scroll"
              >
                <div className="studio-stats__social-main">
                  <div className="studio-stats__social-info">
                    <h2>{person.statistic.instagram.title}</h2>
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.instagram.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            data-label={`Открыть ${person.statistic.instagram.title}`}
                          >
                            Открыть {person.statistic.instagram.title}
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href={person.statistic.instagram.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <ul className="studio-stats__nav" data-scroll="data-scroll">
                    {person.statistic.instagram.country.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="studio-stats__inner">
                            <p>{item.title} </p>
                            <span>{item.percent}%</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="studio-stats__gender">
                    <div className="studio-stats__gender-item">
                      <span>Женщины</span>
                      <div className="studio-stats__line woman" />
                      <span>{person.statistic.instagram.gender.woman}%</span>
                    </div>
                    <div
                      className="studio-stats__gender-item"
                      style={{
                        flex: `0 0 ${person.statistic.instagram.gender.man}%`,
                      }}
                    >
                      <span>Мужчины</span>
                      <div className="studio-stats__line man" />
                      <span>{person.statistic.instagram.gender.man}%</span>
                    </div>
                  </div>
                  {}
                  <ul className="studio-stats__tag">
                    {person.statistic.instagram.tags.map((item, index) => {
                      return <li key={index}>{item.text}</li>;
                    })}
                  </ul>
                </div>
                <div
                  className="studio-stats__inmain fade-in"
                  data-scroll="data-scroll"
                >
                  <div className="studio-stats__social-info">
                    <h2>Статистика</h2>
                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.instagram.statsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Смотреть статистику">
                            Смотреть статистику
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href="https://drive.google.com/drive/folders/1-yW1aPDWgT0gZGUKlaJ-qpSzSXm6oKga"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16}
                    slidesPerView={"3"}
                    observer={"true"}
                    speed={"700"}
                    freeMode={"true"}
                    className="studio-stats__slider"
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      320: {
                        spaceBetween: 10,
                        slidesPerView: 2,
                      },

                      567: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {person.statistic.instagram.slider.map((item, index) => {
                      return (
                        <SwiperSlide>
                          <Fancybox
                            options={{
                              idle: false,
                              compact: false,
                              dragToClose: false,
                              transitionEffect: "fade",
                              animated: true,
                              showClass: "f-fadeSlowIn",
                              hideClass: false,
                              Carousel: {
                                infinite: true,
                              },

                              Images: {
                                zoom: false,
                                Panzoom: {
                                  maxScale: 1.1,
                                },
                              },

                              Toolbar: {
                                absolute: true,
                                display: {
                                  left: [],
                                  middle: [],
                                  right: ["close"],
                                },
                              },

                              Thumbs: {
                                type: "classic",
                                Carousel: {
                                  axis: "x",

                                  slidesPerPage: 2,
                                  Navigation: true,
                                  center: true,
                                  fill: true,
                                  dragFree: true,

                                  breakpoints: {
                                    "(min-width: 640px)": {
                                      axis: "y",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <a
                              data-fancybox="gallery"
                              href={`https://platform.peaches.studio/assets/images/${item.img}`}
                            >
                              <img
                                src={`https://platform.peaches.studio/assets/images/${item.img}`}
                                alt="bloggers"
                              />
                              <div className="studio-stats__zoom">
                                <svg
                                  tabIndex={-1}
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m19.5 4.5-15 15M4.5 4.5l15 15" />
                                </svg>
                              </div>
                            </a>
                          </Fancybox>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
            {person.statistic.tikTok && (
              <div
                className="studio-stats__social fade-in"
                data-scroll="data-scroll"
              >
                <div className="studio-stats__social-main">
                  <div className="studio-stats__social-info">
                    <h2>{person.statistic.tikTok.title}</h2>
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.tikTok.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            data-label={`Открыть ${person.statistic.tikTok.title}`}
                          >
                            Открыть {person.statistic.tikTok.title}
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href={person.statistic.tikTok.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <ul className="studio-stats__nav" data-scroll="data-scroll">
                    {person.statistic.tikTok.country.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="studio-stats__inner">
                            <p>{item.title} </p>
                            <span>{item.percent}%</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="studio-stats__gender">
                    <div className="studio-stats__gender-item">
                      <span>Женщины</span>
                      <div className="studio-stats__line woman" />
                      <span>{person.statistic.tikTok.gender.woman}%</span>
                    </div>
                    <div
                      className="studio-stats__gender-item"
                      style={{
                        flex: `0 0 ${person.statistic.tikTok.gender.man}%`,
                      }}
                    >
                      <span>Мужчины</span>
                      <div className="studio-stats__line man" />
                      <span>{person.statistic.tikTok.gender.man}%</span>
                    </div>
                  </div>
                  {}
                  <ul className="studio-stats__tag">
                    {person.statistic.tikTok.tags.map((item, index) => {
                      return <li key={index}>{item.text}</li>;
                    })}
                  </ul>
                </div>
                <div
                  className="studio-stats__inmain fade-in"
                  data-scroll="data-scroll"
                >
                  <div className="studio-stats__social-info">
                    <h2>Статистика</h2>
                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.tikTok.statsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Смотреть статистику">
                            Смотреть статистику
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href="https://drive.google.com/drive/folders/1-yW1aPDWgT0gZGUKlaJ-qpSzSXm6oKga"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16}
                    slidesPerView={"3"}
                    observer={"true"}
                    speed={"700"}
                    freeMode={"true"}
                    className="studio-stats__slider"
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      320: {
                        spaceBetween: 10,
                        slidesPerView: 2,
                      },

                      567: {
                        spaceBetween: 16,
                        slidesPerView: 3,
                      },
                    }}
                  >
                    {person.statistic.tikTok.slider.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Fancybox
                            options={{
                              idle: false,
                              compact: false,
                              dragToClose: false,
                              transitionEffect: "fade",
                              animated: true,
                              showClass: "f-fadeSlowIn",
                              hideClass: false,
                              Carousel: {
                                infinite: true,
                              },

                              Images: {
                                zoom: false,
                                Panzoom: {
                                  maxScale: 1.1,
                                },
                              },

                              Toolbar: {
                                absolute: true,
                                display: {
                                  left: [],
                                  middle: [],
                                  right: ["close"],
                                },
                              },

                              Thumbs: {
                                type: "classic",
                                Carousel: {
                                  axis: "x",

                                  slidesPerPage: 2,
                                  Navigation: true,
                                  center: true,
                                  fill: true,
                                  dragFree: true,

                                  breakpoints: {
                                    "(min-width: 640px)": {
                                      axis: "y",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <a
                              data-fancybox="gallery"
                              href={`https://platform.peaches.studio/assets/images/${item.img}`}
                            >
                              <img
                                src={`https://platform.peaches.studio/assets/images/${item.img}`}
                                alt="bloggers"
                              />
                              <div className="studio-stats__zoom">
                                <svg
                                  tabIndex={-1}
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m19.5 4.5-15 15M4.5 4.5l15 15" />
                                </svg>
                              </div>
                            </a>
                          </Fancybox>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
            {person.statistic.vk && (
              <div
                className="studio-stats__social fade-in"
                data-scroll="data-scroll"
              >
                <div className="studio-stats__social-main">
                  <div className="studio-stats__social-info">
                    <h2>{person.statistic.vk.title}</h2>
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.vk.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span
                            data-label={`Открыть ${person.statistic.vk.title}`}
                          >
                            Открыть {person.statistic.vk.title}
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href={person.statistic.vk.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <ul className="studio-stats__nav" data-scroll="data-scroll">
                    {person.statistic.vk.country.map((item, index) => {
                      return (
                        <li key={index}>
                          <div className="studio-stats__inner">
                            <p>{item.title} </p>
                            <span>{item.percent}%</span>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="studio-stats__gender">
                    <div className="studio-stats__gender-item">
                      <span>Женщины</span>
                      <div className="studio-stats__line woman" />
                      <span>{person.statistic.vk.gender.woman}%</span>
                    </div>
                    <div
                      className="studio-stats__gender-item"
                      style={{
                        flex: `0 0 ${person.statistic.vk.gender.man}%`,
                      }}
                    >
                      <span>Мужчины</span>
                      <div className="studio-stats__line man" />
                      <span>{person.statistic.vk.gender.man}%</span>
                    </div>
                  </div>
                  {}
                  <ul className="studio-stats__tag">
                    {person.statistic.vk.tags.map((item, index) => {
                      return <li key={index}>{item.text}</li>;
                    })}
                  </ul>
                </div>
                <div
                  className="studio-stats__inmain fade-in"
                  data-scroll="data-scroll"
                >
                  <div className="studio-stats__social-info">
                    <h2>Статистика</h2>
                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <a
                          href={person.statistic.vk.statsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Смотреть статистику">
                            Смотреть статистику
                          </span>
                        </a>
                      </div>
                      <img
                        className="lazy-load"
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                      <a
                        className="mobile"
                        href="https://drive.google.com/drive/folders/1-yW1aPDWgT0gZGUKlaJ-qpSzSXm6oKga"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="lazy-load"
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </a>
                    </div>
                  </div>
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={16}
                    slidesPerView={person.statistic.vk.statsLink ? 2 : 1}
                    observer={"true"}
                    speed={"700"}
                    freeMode={"true"}
                    className="studio-stats__slider"
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                      320: {
                        spaceBetween: 10,
                        slidesPerView: 1,
                      },

                      567: {
                        spaceBetween: 16,
                        slidesPerView: person.statistic.vk.statsLink ? 2 : 1,
                      },
                    }}
                  >
                    {person.statistic.vk.slider.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <Fancybox
                            options={{
                              idle: false,
                              compact: false,
                              dragToClose: false,
                              transitionEffect: "fade",
                              animated: true,
                              showClass: "f-fadeSlowIn",
                              hideClass: false,
                              Carousel: {
                                infinite: true,
                              },

                              Images: {
                                zoom: false,
                                Panzoom: {
                                  maxScale: 1.1,
                                },
                              },

                              Toolbar: {
                                absolute: true,
                                display: {
                                  left: [],
                                  middle: [],
                                  right: ["close"],
                                },
                              },

                              Thumbs: {
                                type: "classic",
                                Carousel: {
                                  axis: "x",

                                  slidesPerPage: 2,
                                  Navigation: true,
                                  center: true,
                                  fill: true,
                                  dragFree: true,

                                  breakpoints: {
                                    "(min-width: 640px)": {
                                      axis: "y",
                                    },
                                  },
                                },
                              },
                            }}
                          >
                            <a
                              data-fancybox="gallery"
                              href={`https://platform.peaches.studio/assets/images/${item.img}`}
                            >
                              <img
                                src={`https://platform.peaches.studio/assets/images/${item.img}`}
                                alt="bloggers"
                              />
                              <div className="studio-stats__zoom">
                                <svg
                                  tabIndex={-1}
                                  width={24}
                                  height={24}
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m19.5 4.5-15 15M4.5 4.5l15 15" />
                                </svg>
                              </div>
                            </a>
                          </Fancybox>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            )}
          </div>
          <div className="studio-stats__grid">
            {person.socialMedia &&
              person.socialMedia.social.map((media, index) => {
                return (
                  <div className="studio-stats__grid-item " key={index}>
                    <div className="studio-stats__grid-info">
                      <h2>{media.title}</h2>
                      <div className="studio-stats__button">
                        <div className="btn-outline">
                          <a
                            href={media.socialLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span data-label={media.title}>{media.title}</span>
                          </a>
                        </div>
                        <img
                          src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                          alt="arrow"
                        />
                      </div>
                    </div>
                    <div className="studio-stats__button dark">
                      <div className="btn-outline">
                        <a href={media.link} target="_blank" rel="noreferrer">
                          <span data-label="Смотреть статистику">
                            Смотреть статистику
                          </span>
                        </a>
                      </div>
                      <img
                        src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                        alt="arrow"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="studio-stats__inrow fade-in">
            <div className="studio-stats__initem">
              <h2>Медийность</h2>
              <div className="studio-stats__ingrid-row">
                {person.media.youtube.map((item, index) => {
                  return (
                    <div className="studio-stats__ingrid" key={index}>
                      <div className="studio-stats__overlay">
                        <img
                          src={`https://platform.peaches.studio/assets/images/${item.bg}`}
                          alt="youtube cover"
                        />
                      </div>

                      <div className="studio-stats__ingrid-item">
                        <div className="studio-stats__ingrid-box">
                          <h3>{item.title}</h3>
                          <p>{item.text}</p>
                        </div>
                        <div className="studio-stats__button">
                          <div className="btn-outline">
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span data-label="Смотреть">Смотреть</span>
                            </a>
                          </div>
                          <img
                            className="lazy-load"
                            src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                            alt="arrow"
                          />
                          <a
                            className="mobile"
                            href="https://www.youtube.com/watch?v=97PKNWOJg0Y&ab_channel=%D0%90%D1%80%D0%B8%D0%BD%D0%B0%D0%94%D0%B0%D0%BD%D0%B8%D0%BB%D0%BE%D0%B2%D0%B0"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="lazy-load"
                              src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                              alt="arrow"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="studio-stats__information">
              <h2>
                Будем рады
                <br />
                сотрудничеству!
              </h2>
              <div className="studio-stats__button dark">
                <div className="btn-outline">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1tiRLEJgsEGG7bPuiovk1IQfoUbI0IvIeNBRMqdRtwZs/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span data-label="Прайс-лист">Прайс-лист</span>
                  </a>
                </div>
                <img
                  className="lazy-load"
                  src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                  alt="arrow"
                />
                <a
                  className="mobile"
                  href="https://www.youtube.com/watch?v=ZoMGx9wtLig&feature=youtu.be "
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="lazy-load"
                    src="https://platform.peaches.studio/assets/images/icon/arrow.svg"
                    alt="arrow"
                  />
                </a>
              </div>
              <div className="studio-stats__mores">
                <div className="studio-stats__name">
                  <h3>
                    Ксения Калишевская<span>CCO</span>
                  </h3>
                  <div className="studio-stats__information-row">
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href="https://t.me/kalishevskaya"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Написать">Написать</span>
                        </a>
                      </div>
                      <img
                        alt="phone"
                        src="https://platform.peaches.studio/assets/images/icon/telegram.svg"
                      />
                      <a
                        className="mobile"
                        href="https://t.me/kalishevskaya"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="phone"
                          src="https://platform.peaches.studio/assets/images/icon/telegram.svg"
                          data-ll-status="loading"
                        />
                      </a>
                    </div>
                    <div className="studio-stats__button">
                      <div className="btn-outline">
                        <a
                          href="mailto:ksenia@peaches.studio"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span data-label="Написать">Написать</span>
                        </a>
                      </div>
                      <img
                        src="https://platform.peaches.studio/assets/images/icon/mail.svg"
                        alt="main"
                      />
                      <a
                        className="mobile"
                        href="mailto:ksenia@peaches.studio"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          alt="main"
                          src="https://platform.peaches.studio/assets/images/icon/mail.svg"
                          data-ll-status="loading"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
